import { createKey, defineApi, defineMutation } from '../../core'
import { type RouteTypes } from '../types'

export const queryKeys = {
  root: createKey('subscriptions'),
  individualAndTeam: [
    'subscriptions',
    {
      individual: true,
      team: true,
    },
  ],
}

type RefreshSubscription = RouteTypes<'SubscriptionsController_getSubscription'>
export const refresh = defineApi<
  RefreshSubscription['request'],
  RefreshSubscription['response']
>().using({
  key: queryKeys.root,
  uri: '/subscriptions',
  method: 'POST',
})

type ApplyCoupon = RouteTypes<'SubscriptionsController_applyCoupon'>
export const applyCoupon = defineMutation<
  ApplyCoupon['request'],
  ApplyCoupon['response']
>().using({
  uri: `/subscriptions/coupon`,
  method: 'POST',
  invalidate: [queryKeys.root, queryKeys.individualAndTeam],
})

type CancelSubscription =
  RouteTypes<'SubscriptionsController_cancelSubscription'>
export const cancelSubscription = defineMutation<
  CancelSubscription['request'],
  CancelSubscription['response']
>().using({
  uri: `/subscriptions/cancel`,
  method: 'POST',
  invalidate: [queryKeys.root, queryKeys.individualAndTeam],
})

type UncancelSubscription =
  RouteTypes<'SubscriptionsController_uncancelSubscription'>
export const uncancelSubscription = defineMutation<
  UncancelSubscription['request'],
  UncancelSubscription['response']
>().using({
  uri: `/subscriptions/uncancel`,
  method: 'POST',
  invalidate: [queryKeys.root, queryKeys.individualAndTeam],
})

type GetIndividualAndTeam =
  RouteTypes<'SubscriptionsController_getSubscriptions'>
export const getIndividualAndTeamSubscription = defineApi<
  GetIndividualAndTeam['request'],
  GetIndividualAndTeam['response']
>().using({
  uri: `/subscriptions/individual-and-team`,
  method: 'GET',
  key: queryKeys.individualAndTeam,
  queryOptions: {
    staleTime: 600_000,
  },
})

type GetTeamPrices = RouteTypes<'SubscriptionsController_getTeamPrices'>
export const getTeamPrices = defineApi<
  GetTeamPrices['request'],
  GetTeamPrices['response']
>().using({
  key: ['subscriptions', 'team', 'prices'],
  uri: 'subscriptions/prices/team',
  queryOptions: {
    refetchOnMount: false,
    cacheTime: Infinity,
    staleTime: 600_000,
  },
})

type GetTeamPricesUnauthorized =
  RouteTypes<'SubscriptionsController_getTeamPricesUnauthorized'>
export const getTeamPricesUnauthorized = defineApi<
  GetTeamPricesUnauthorized['request'],
  GetTeamPricesUnauthorized['response']
>().using({
  key: ['subscriptions', 'team', 'prices'],
  uri: (args) =>
    `subscriptions/prices/team/unauthorized?email=${encodeURIComponent(args.email)}`,
  queryOptions: {
    refetchOnMount: false,
    cacheTime: Infinity,
    staleTime: 600_000,
  },
})

type CreateStripePortalLink =
  RouteTypes<'SubscriptionsController_createStripePortalLink'>
export const createStripePortalLink = defineMutation<
  CreateStripePortalLink['request'],
  CreateStripePortalLink['response']
>().using({
  uri: `/subscriptions/stripePortalLink`,
  method: 'POST',
  key: ['subscriptions', 'stripePortalLink'],
})

type GetSubscriptionPaymentMethod =
  RouteTypes<'SubscriptionsController_getSubscriptionPaymentMethod'>
export const getSubscriptionPaymentMethod = defineApi<
  GetSubscriptionPaymentMethod['request'],
  GetSubscriptionPaymentMethod['response']
>().using({
  uri: (args) => `/subscriptions/${args.subscriptionId}/paymentMethod`,
  method: 'GET',
  key: (args) => ['subscriptions', args.subscriptionId, 'paymentMethod'],
  queryOptions: {
    staleTime: 1000 * 60 * 5,
  },
})
