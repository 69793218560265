import { XSolid } from '@motion/icons'
import { classed } from '@motion/theme'

import { type PointerEvent, type SyntheticEvent, useRef, useState } from 'react'

import { UnstyledModal, type UnstyledModalProps } from './unstyled-modal'

import { IconButton } from '../button'

export type ImageViewerModalProps = Pick<
  UnstyledModalProps,
  'visible' | 'onClose'
> & {
  src: string
  alt: string
  zoomAmount?: number
}

export function ImageViewerModal({
  src,
  alt,
  zoomAmount = 2,
  ...rest
}: ImageViewerModalProps) {
  const [isZoomed, setIsZoomed] = useState(false)
  const [isVertical, setIsVertical] = useState(false)

  const containerRef = useRef<HTMLDivElement>(null)
  const zoomedRef = useRef<HTMLImageElement>(null)

  const onPointerMove = (e: PointerEvent<HTMLDivElement>) => {
    requestAnimationFrame(() => {
      if (
        !isZoomed ||
        zoomedRef.current == null ||
        containerRef.current == null
      )
        return

      const containerRect = containerRef.current.getBoundingClientRect()
      const { clientX, clientY } = e

      const xDiff = clientX - containerRect.x
      const yDiff = clientY - containerRect.y

      zoomedRef.current.style.transform = constructTransform(
        zoomAmount,
        xDiff * (1 - zoomAmount),
        yDiff * (1 - zoomAmount)
      )
    })
  }

  const onImageLoad = (e: SyntheticEvent<HTMLImageElement>) => {
    const { naturalHeight, naturalWidth } = e.currentTarget

    setIsVertical(naturalHeight > naturalWidth)
  }

  return (
    <UnstyledModal {...rest} withAnimation overlayClassName='bg-modal-overlay'>
      <ZoomArea
        onPointerEnter={() => setIsZoomed(true)}
        onPointerLeave={() => setIsZoomed(false)}
        onPointerMove={onPointerMove}
        ref={containerRef}
        vertical={isVertical}
      >
        <Image src={src} alt={alt} />

        <Image
          src={src}
          alt={alt}
          ref={zoomedRef}
          onLoad={onImageLoad}
          style={{
            transform: constructTransform(zoomAmount, 0, 0),
            transformOrigin: 'top left',
            position: 'absolute',
            top: '0px',
            left: '0px',
            zIndex: 1,
            opacity: isZoomed ? 1 : 0,
          }}
        />
      </ZoomArea>
      <div className='absolute top-0 -right-7'>
        <IconButton
          icon={XSolid}
          size='small'
          sentiment='onDark'
          variant='muted'
          aria-label='Close'
          onClick={rest.onClose}
        />
      </div>
    </UnstyledModal>
  )
}

function constructTransform(
  scale: number,
  translateX: number,
  translateY: number
) {
  return `translate(${translateX}px, ${translateY}px) scale(${scale})`
}

const ZoomArea = classed('div', {
  base: `
    flex items-center justify-center
    relative
    overflow-hidden

    setvar-[container-padding=5px]
    sm:setvar-[container-padding=72px]
    max-w-[min(1200px,calc(100vw-var(--container-padding)))]
    max-h-[min(900px,calc(100vh-var(--container-padding)))]
  `,
  variants: {
    vertical: {
      true: 'h-screen w-auto',
      false: 'h-auto w-screen',
    },
  },
})

const Image = classed('img', {
  base: `
    h-full
    w-full
    object-contain
    cursor-zoom-in
  `,
})
