import { type BillingPrices } from './constants'

import { templateStr } from '../utils'

export type generateBillingFormulaProps = {
  isTeam: boolean
  isAnnual: boolean
  seats?: number
  monthlyPrice: number
}

export function generateBillingFormula({
  isTeam,
  isAnnual,
  seats,
  monthlyPrice,
}: generateBillingFormulaProps): string {
  if (isTeam && seats) {
    if (isAnnual) {
      return `$${monthlyPrice}/mo x ${seats} seats x 12 months = $${
        seats * monthlyPrice * 12
      }/year`
    }
    return `$${monthlyPrice}/mo x ${seats} seats = $${
      seats * monthlyPrice
    }/month`
  }
  if (isAnnual) {
    return `$${monthlyPrice}/mo x 12 months = $${monthlyPrice * 12}/year`
  }
  return `$${monthlyPrice} per month`
}

export type makeTeamBillingStrProps = {
  isAnnual: boolean
  quantity: number
  isSeats?: boolean
  prorationTextParams?: prorationTextParams
  teamPrices: BillingPrices
  shouldShowSalesTaxMessage: boolean
}

type prorationTextParams = {
  isTrial: boolean
  downgradeDate?: string
}

const makeProrationText = (isAnnual: boolean, params?: prorationTextParams) => {
  if (!params) {
    return '.'
  } else if (params.isTrial) {
    return ' after your trial ends.'
  } else if (params.downgradeDate) {
    return `. This new price will take effect on your next invoice (${params.downgradeDate}).`
  }
  return `. The first ${
    isAnnual ? 'year' : 'month'
  } amount will be pro-rated starting today.`
}

export function makeTeamBillingStr({
  isAnnual,
  quantity,
  isSeats,
  prorationTextParams,
  teamPrices,
  shouldShowSalesTaxMessage,
}: makeTeamBillingStrProps): string {
  return templateStr(
    "You'll be charged ${{monthlyPrice}}/mo per {{seatOrUser}} {{annualPrice}}billed {{interval}}{{applicableTax}}{{prorationText}}",
    {
      monthlyPrice: isAnnual
        ? teamPrices.annualPricePerMonth
        : teamPrices.monthlyPrice,
      seatOrUser: isSeats ? 'seat' : 'user',
      annualPrice: isAnnual
        ? `($${(teamPrices.annualPrice * quantity).toLocaleString()}/year) `
        : '',
      interval: isAnnual ? 'annually' : 'monthly',
      prorationText: makeProrationText(isAnnual, prorationTextParams),
      applicableTax: shouldShowSalesTaxMessage
        ? ' (plus applicable taxes)'
        : '',
    }
  )
}

export function makeTeamResubscribeCtaStr(numSeats: number) {
  if (numSeats > 1) {
    return `${numSeats} of your team members have lost access to Motion. Re-subscribe to a team plan!`
  }

  return 'Your team members have lost access to Motion. Re-subscribe to a team plan!'
}

export const teamElevatorPitch =
  'Use A.I. to automate project planning & task prioritization for your entire team. Ensure projects get delivered on-time without hours wasted on planning, coordinating, and following manual SOPs.'

export const teamElevatorPitchV2 = 'Supports teams of less than 20 users'

export function makeTeamBullets(showV2: boolean, savings = 0) {
  return [
    ...(showV2 ? teamBulletsV2 : teamBullets),
    ...(savings > 0 ? [`${savings}% cheaper than individual plan`] : []),
  ]
}

export const teamBulletsShort = [
  'Automatically compute the optimal daily plan for each team member across dozens of projects.',
  'Ensure every team member is always working on the most critical and urgent work.',
  'Full visibility and transparency on what everyone is working on with Team Schedule.',
  'Automate resource and capacity planning.',
  'Active warnings when projects are at-risk of missing deadlines.',
  'Predict exactly when every task and project will be completed.',
  'Automatically redo planning when things change.',
  'Automate Standard Operating Procedures with Project Workflow Automation Templates.',
  'Replaces Monday, ClickUp, Asana, Notion, Airtable, Excel...etc.',
  'SOC 2 Type 2 Compliant. Security Report available.',
]

export const teamBullets = [
  'Automatically compute the most optimal daily plan for each team member across dozens of projects and hundreds of tasks, by taking into account all their deadlines, priorities, dependencies, and many factors.',
  'Ensure every team member is always working on the most critical and urgent work.',
  'Full visibility and transparency on what everyone is working on with Team Schedule.',
  'Automate resource and capacity planning.',
  'Active warnings when projects are at-risk of missing deadlines.',
  'Predict exactly when every task and project will be completed.',
  'Automatically redo planning when things change.',
  'Automate Standard Operating Procedures with Project Workflow Automation Templates.',
  'Replaces Monday, ClickUp, Asana, Notion, Airtable, Excel...etc.',
  'Centralized billing.',
  'SOC 2 Type 2 Compliant. Security Report available.',
]

export const teamBulletsV2 = [
  'Project workflow automations (10 concurrent workflows)',
  'Automated team project planning & optimization with A.I.',
  'Predict project delivery date with A.I.',
  'Multi-layer pivot table',
  'Timeline & Gantt views',
  'Time tracking',
  'SOP (Standard Operating Procedure) templates and automations',
  'Centralized billing',
  'Priority customer support',
]

export const individualElevatorPitch =
  'Use A.I. to automatically plan your schedule based on all of your tasks, meetings, and everything else. Ensure your tasks get scheduled on time without manual planning.'

export const individualElevatorPitchV2 = 'Supports 1 user'

export const individualBullets = [
  'Automatically plan your day with A.I.',
  'Receive warning when you might miss deadline',
  'Automatically reshuffle when priorities change',
  'Tells you the most important task at any moment',
  'More focus and deep work time',
  'Sync between personal and all work calendars',
  'Next-gen calendar interface',
  'Create meeting booking pages',
  'Web app, mobile app, and Desktop app',
]

export const individualBulletsV2 = [
  'Automated scheduling and planning with A.I.',
  'Project and task management',
  'Calendar management',
  'Meeting booking pages & templates',
  'Desktop app',
  'iOS and Android apps',
  'Customer support',
]

export const enterpriseBullets = [
  'Dedicated project management expert to help you set everything up and onboard your entire team ($10,000 worth of value) for free',
  'Ongoing dedicated help and regular review of your process from our project management expert for free',
  'Highly prioritized customer success and support team',
]

export const enterpriseBulletsV2 = [
  'Project Workflow Automations (Unlimited workflows)',
  'Team Reporting: ensures accountability and visibility',
  'API access, Zapier & Integrations',
  'Resource & Capacity planning automations',
  'Enterprise-grade security and governance',
  'Complimentary dedicated project management expert for implementation and onboarding ($10,000 worth of value).',
  'Ongoing dedicated project management expert for customer success',
]

export const enterpriseElevatorPitch =
  '1 on 1 project management setup and onboarding ($10,000 value), ongoing expert support, regular process reviews, and top-priority customer service for teams of 25+ members.'

export const enterpriseElevatorPitchV2 = 'Supports teams of 20 users or more'

export const makeTeamTrialOnIndividualBillingTermsStr = (
  price: number,
  isAnnual: boolean,
  trialEnd: string,
  trialDays: number
) => {
  return templateStr(
    "You'll be charged ${{price}} when your trial ends on {{trialEnd}} (in {{dayPlural}}). Any remaining balance on your Individual Plan will be credited to your Team Plan. After that you'll be charged ${{price}} {{interval}} and your plan will be upgraded from Individual to Team!",
    {
      price,
      interval: isAnnual ? 'annually' : 'monthly',
      trialEnd,
      dayPlural: `${trialDays} day${trialDays === 1 ? '' : 's'}`,
    }
  )
}
