import { API, createUseMutation, createUseQuery } from '@motion/rpc'
import { type ClientFirebaseSettingsDto } from '@motion/rpc-types'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'

import { useCurrentUserOrNull } from '../auth/hooks'
import { firebase } from '../firebase'

export const useSetting = <TKey extends keyof ClientFirebaseSettingsDto>(
  name: TKey,
  defaultValue: ClientFirebaseSettingsDto[TKey]
): [
  ClientFirebaseSettingsDto[TKey],
  (val: ClientFirebaseSettingsDto[TKey]) => void,
] => {
  const { mutate } = useSaveFirebaseSettings()
  const { data } = useFirestoreSettings(undefined, {
    // @ts-expect-error - return type of createUseQuery doesn't support 'select' properly
    select(data) {
      return data[name]
    },
  })

  // @ts-expect-error - return type of createUseQuery doesn't support 'select' properly
  const value: ClientFirebaseSettingsDto[TKey] = data ?? defaultValue

  const setValue = useCallback(
    (val: ClientFirebaseSettingsDto[TKey]) =>
      mutate({
        [name]: val,
      }),
    [mutate, name]
  )

  return [value, setValue]
}

type SettingsSubset<TKeys extends keyof ClientFirebaseSettingsDto> = {
  [TKey in TKeys]: ClientFirebaseSettingsDto[TKey]
}

export const useSettings = <TKeys extends (keyof ClientFirebaseSettingsDto)[]>(
  keys: TKeys
): SettingsSubset<TKeys[number]> | undefined => {
  const query = useFirestoreSettings(undefined, {
    // @ts-expect-error - return type of createUseQuery doesn't support 'select' properly
    select(data) {
      return keys.reduce((acc, key) => {
        if (!(key in data)) return acc
        // @ts-expect-error - typed externally
        acc[key] = data[key]
        return acc
      }, {})
    },
  })

  return query.data as unknown as SettingsSubset<TKeys[number]> | undefined
}

export const useFirestoreSettings = createUseQuery(
  API.userSettings.getFirestoreSettings,
  {
    cacheTime: Infinity,
    notifyOnChangeProps: ['data', 'dataUpdatedAt', 'error'],
  }
)

export const SettingsQueryKey = ['settings', 'firestore']

type UpdateSettings = Partial<ClientFirebaseSettingsDto>

export const useSaveFirebaseSettings = () => {
  const client = useQueryClient()
  const user = useCurrentUserOrNull()

  return useMutation({
    async mutationFn(data: UpdateSettings) {
      if (user == null) {
        throw new Error('User is not authenticated')
      }

      await firebase
        .firestore()
        .collection<ClientFirebaseSettingsDto>('settings')
        .doc(user.uid)
        .set(data, { merge: true })
    },
    onMutate(data) {
      const existing =
        client.getQueryData<ClientFirebaseSettingsDto>(SettingsQueryKey)
      const merged = {
        ...existing,
        ...data,
      } as ClientFirebaseSettingsDto

      client.setQueryData(SettingsQueryKey, merged)

      return {
        prev: existing,
        merged,
      }
    },
    onError() {
      client.invalidateQueries({
        queryKey: SettingsQueryKey,
      })
    },
  })
}

export const useMySettings = createUseQuery(API.usersV2.getMySettings, {
  select: (data) => data.models.userSettings[data.id],
})

export const useSavePostgresOnboardingSettings = createUseMutation(
  API.usersV2.updatePostgresOnboardingSettings
)

export const useSavePostgresConferenceSettings = createUseMutation(
  API.usersV2.updatePostgresConferenceSettings
)
