/**
 * Incredibly, Tiptap takes it upon itself to escape certain characters in the HTML output of the editor, not optionally.
 * See https://github.com/ueberdosis/tiptap/issues/4007.
 *
 * This function unescapes those characters.
 *
 * Note that we can't apply this to all uses of the RTE,
 * because in some cases (see StageTaskNameField) we want to strip html tags before unescaping.
 */
export function unescapeTiptapOutput(html: string): string {
  const htmlEntities: { [key: string]: string } = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
  }
  return html.replace(/&amp;|&lt;|&gt;/g, (match) => htmlEntities[match])
}
