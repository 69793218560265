import { RpcProvider as BaseProvider, setLoaderContext } from '@motion/rpc'
import { useAuthToken, useHasCookieAuth } from '@motion/web-common/auth'

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { type ReactNode, useEffect } from 'react'

import { client } from './client'
import { STATIC_HEADERS } from './constants'

import { useHasTreatment } from '../flags'
import { useLocalStorage } from '../storage'

type Props = {
  children: ReactNode
}

declare module '../storage/local-storage/use-local-storage' {
  interface LocalStorageTypes {
    'motion:dev-tools': boolean
  }
}

export const ConnectedRpcProvider = (props: Props) => {
  const token = useAuthToken()
  const hasCookieAuth = useHasCookieAuth()

  const [devToolsVisible, setDevToolsVisible] = useLocalStorage(
    'motion:dev-tools',
    true
  )

  const devToolsEnabled = useHasTreatment('development-panel')

  if (!__IS_PROD__) {
    // @ts-expect-error - dev only
    window.toggleDevTools = () => setDevToolsVisible((prev) => !prev)
  }

  useEffect(() => {
    setLoaderContext(client, {
      token,
      baseUri: __BACKEND_HOST__,
      headers: STATIC_HEADERS,
    })
  }, [token])

  return (
    <BaseProvider
      client={client}
      token={hasCookieAuth ? undefined : () => Promise.resolve(token)}
      baseUri={__BACKEND_HOST__}
      headers={STATIC_HEADERS}
    >
      {devToolsEnabled && devToolsVisible && <ReactQueryDevtools />}
      {props.children}
    </BaseProvider>
  )
}
