import { useHasTreatment } from '@motion/web-common/flags'

import { useCallback } from 'react'

import { usePersistedOnboardingStateFirestore } from './use-persisted-onboarding-state-firestore'
import { usePersistedOnboardingStatePostgres } from './use-persisted-onboarding-state-postgres'

import { type PersistedOnboardingState } from '../../types'

export const usePersistedOnboardingState = () => {
  const shouldReadPostgres = useHasTreatment(
    'kill-firestore-onboarding-settings-read'
  )
  const shouldDualWrite = useHasTreatment(
    'kill-firestore-onboarding-settings-dual-write'
  )

  const [firestoreData, firestoreUpdate] =
    usePersistedOnboardingStateFirestore()
  const [postgresData, postgresUpdate] = usePersistedOnboardingStatePostgres({
    read: shouldReadPostgres,
    write: shouldDualWrite,
  })

  const dualUpdate = useCallback(
    async (data: Partial<PersistedOnboardingState>) => {
      // The firestoreUpdate is technically redundant but do it to be sure
      await firestoreUpdate(data)
      await postgresUpdate(data)
    },
    [firestoreUpdate, postgresUpdate]
  )

  return [
    shouldReadPostgres ? postgresData : firestoreData,
    shouldDualWrite ? dualUpdate : firestoreUpdate,
  ] as const
}
