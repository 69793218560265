import type { COLOR } from '@motion/shared/common'

import { DateTime, Interval } from 'luxon'
import { useCallback, useMemo, useState } from 'react'

import { type DateRangeColorData } from './types'
import { dateToDateStringKey, getDatesBetween } from './utils'

export enum Month {
  JANUARY,
  FEBRUARY,
  MARCH,
  APRIL,
  MAY,
  JUNE,
  JULY,
  AUGUST,
  SEPTEMBER,
  OCTOBER,
  NOVEMBER,
  DECEMBER,
}

export enum Day {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
}

export interface Options {
  /**
   * What day a week starts on within the calendar matrix.
   *
   * @default Day.SUNDAY
   */
  weekStartsOn?: Day

  /**
   * The initial viewing date.
   *
   * @default DateTime.mow()
   */
  viewing?: DateTime

  /**
   * The initial date(s) selection.
   *
   * @default []
   */
  selected?: DateTime[]

  /**
   * The number of months in the calendar.
   *
   * @default 1
   */
  numberOfMonths?: number

  /*
   * Date ranges to color on the calendar for context
   *
   * ex. stage start/end, project start/end, etc
   *
   * @default []
   * */
  dateRangeColors?: DateRangeColorData[]
}

export interface Returns {
  /**
   * Returns a copy of the given date with the time set to 00:00:00:00.
   */
  clearTime: (date: DateTime) => DateTime

  /**
   * Returns whether or not a date is between 2 other dates (inclusive).
   */
  inRange: (date: DateTime, min: DateTime, max: DateTime) => boolean

  /**
   * The date represented in the calendar matrix. Note that
   * the month and year are the only parts used.
   */
  viewing: DateTime

  /**
   * Set the date represented in the calendar matrix. Note that
   * the month and year are the only parts used.
   */
  setViewing: React.Dispatch<React.SetStateAction<DateTime>>

  /**
   * Set the viewing date to today.
   */
  viewToday: () => void

  /**
   * Set the viewing date to the given month.
   */
  viewMonth: (month: Month) => void

  /**
   * Set the viewing date to the month before the current.
   */
  viewPreviousMonth: () => void

  /**
   * Set the viewing date to the month after the current.
   */
  viewNextMonth: () => void

  /**
   * Set the viewing date to the given year.
   */
  viewYear: (year: number) => void

  /**
   * Set the viewing date to the year before the current.
   */
  viewPreviousYear: () => void

  /**
   * Set the viewing date to the year after the current.
   */
  viewNextYear: () => void

  /**
   * The dates currently selected.
   */
  selected: DateTime[]

  /**
   * Override the currently selected dates.
   */
  setSelected: React.Dispatch<React.SetStateAction<DateTime[]>>

  /**
   * Reset the selected dates to [].
   */
  clearSelected: () => void

  /**
   * Determine whether or not a date has been selected.
   */
  isSelected: (date: DateTime) => boolean

  /**
   * Select one or more dates.
   */
  select: (date: DateTime | DateTime[], replaceExisting?: boolean) => void

  /**
   * Deselect one or more dates.
   */
  deselect: (date: DateTime | DateTime[]) => void

  /**
   * Toggle the selection of a date.
   */
  toggle: (date: DateTime, replaceExisting?: boolean) => void

  /**
   * Select a range of dates (inclusive).
   */
  selectRange: (
    start: DateTime,
    end: DateTime,
    replaceExisting?: boolean
  ) => void

  /**
   * Deselect a range of dates (inclusive).
   */
  deselectRange: (start: DateTime, end: DateTime) => void

  /*
   * A map of date strings (at startOf('day')) to colors for context
   * */
  dateStringToColor: Map<string, COLOR>

  /**
   * A matrix of days based on the current viewing date.
   */
  calendar: DateTime[][][]
}

const eachMonthInInterval = (start: DateTime, end: DateTime) => {
  const interval = Interval.fromDateTimes(start, end)
  const months: Interval[] = []
  let currentDate = interval.start
  while (interval.contains(currentDate)) {
    const startNexMonth = currentDate.startOf('month').plus({ month: 1 })
    months.push(
      Interval.fromDateTimes(currentDate.startOf('month'), startNexMonth)
    )
    currentDate = startNexMonth
  }
  return months
}

const eachWeekInInterval = (
  start: DateTime,
  end: DateTime,
  weekStartsOn: Day
) => {
  // luxon weeks start on mondays so we substaract one day to start on sunday
  const interval = Interval.fromDateTimes(
    start.startOf('week').plus({ day: weekStartsOn - 1 }),
    end
  )
  const weeks: Interval[] = []
  let currentDate = interval.start
  for (let i = 0; i < 6; i++) {
    const startNexWeek = currentDate.plus({ week: 1 })
    weeks.push(Interval.fromDateTimes(currentDate, startNexWeek))
    currentDate = startNexWeek
  }
  return weeks
}

const eachDayInInterval = (start: DateTime, end: DateTime) => {
  const interval = Interval.fromDateTimes(start, end)
  const days: DateTime[] = []
  let currentDate = interval.start
  for (let i = 0; i < 7; i++) {
    days.push(currentDate.startOf('day'))
    currentDate = currentDate.plus({ day: 1 })
  }
  return days
}

const inRange = (date: DateTime, min: DateTime, max: DateTime) =>
  Interval.fromDateTimes(min, max).contains(date)

const clearTime = (date: DateTime) =>
  date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

export const useDatePicker = ({
  weekStartsOn = Day.SUNDAY,
  viewing: initialViewing = DateTime.now(),
  selected: initialSelected = [],
  numberOfMonths = 1,
  dateRangeColors = [],
}: Options = {}): Returns => {
  const [viewing, setViewing] = useState<DateTime>(initialViewing)

  const viewToday = useCallback(
    () => setViewing(DateTime.now().startOf('day')),
    [setViewing]
  )

  const viewMonth = useCallback(
    (month: Month) => setViewing((v) => v.set({ month })),
    []
  )

  const viewPreviousMonth = useCallback(
    () => setViewing((v) => v.minus({ month: 1 })),
    []
  )

  const viewNextMonth = useCallback(
    () => setViewing((v) => v.plus({ month: 1 })),
    []
  )

  const viewYear = useCallback(
    (year: number) => setViewing((v) => v.set({ year })),
    []
  )

  const viewPreviousYear = useCallback(
    () => setViewing((v) => v.minus({ year: 1 })),
    []
  )

  const viewNextYear = useCallback(
    () => setViewing((v) => v.plus({ year: 1 })),
    []
  )

  const [selected, setSelected] = useState<DateTime[]>(
    initialSelected.map(clearTime)
  )

  const clearSelected = useCallback(() => setSelected([]), [])

  const isSelected = useCallback(
    (date: DateTime) => selected.findIndex((s) => s.hasSame(date, 'day')) > -1,
    [selected]
  )

  const select = useCallback(
    (date: DateTime | DateTime[], replaceExisting?: boolean) => {
      if (replaceExisting) {
        setSelected(Array.isArray(date) ? date : [date])
      } else {
        setSelected((selectedItems) =>
          selectedItems.concat(Array.isArray(date) ? date : [date])
        )
      }
    },
    []
  )

  const deselect = useCallback(
    (date: DateTime | DateTime[]) =>
      setSelected((selectedItems) =>
        Array.isArray(date)
          ? selectedItems.filter(
              (s) => !date.map((d) => d.toMillis()).includes(s.toMillis())
            )
          : selectedItems.filter((s) => !s.equals(date))
      ),
    []
  )

  const toggle = useCallback(
    (date: DateTime, replaceExisting?: boolean) =>
      isSelected(date) ? deselect(date) : select(date, replaceExisting),
    [deselect, isSelected, select]
  )

  const selectRange = useCallback(
    (start: DateTime, end: DateTime, replaceExisting?: boolean) => {
      if (replaceExisting) {
        setSelected(eachDayInInterval(start, end))
      } else {
        setSelected((selectedItems) =>
          selectedItems.concat(eachDayInInterval(start, end))
        )
      }
    },
    []
  )

  const deselectRange = useCallback((start: DateTime, end: DateTime) => {
    setSelected((selectedItems) =>
      selectedItems.filter(
        (s) =>
          !eachDayInInterval(start, end)
            .map((d) => d.toMillis())
            .includes(s.toMillis())
      )
    )
  }, [])

  const calendar = useMemo<DateTime[][][]>(
    () =>
      eachMonthInInterval(
        viewing.startOf('month'),
        viewing.plus({ month: numberOfMonths - 1 }).endOf('month')
      ).map((month) =>
        eachWeekInInterval(month.start, month.end, weekStartsOn).map((week) =>
          eachDayInInterval(week.start, week.end)
        )
      ),
    [viewing, weekStartsOn, numberOfMonths]
  )

  const dateStringToColor = useMemo(() => {
    const _dateStringToColor = new Map<string, COLOR>()
    dateRangeColors.forEach((range) => {
      const { startDate, endDate, color } = range

      if (color == null) {
        return
      }

      const daysBetween = getDatesBetween(startDate, endDate)
      daysBetween.forEach((day) => {
        const dateStringKey = dateToDateStringKey(day)
        if (!_dateStringToColor.has(dateStringKey)) {
          _dateStringToColor.set(dateStringKey, color)
        }
      })
    })
    return _dateStringToColor
  }, [dateRangeColors])

  return {
    clearTime,
    inRange,
    viewing,
    setViewing,
    viewToday,
    viewMonth,
    viewPreviousMonth,
    viewNextMonth,
    viewYear,
    viewPreviousYear,
    viewNextYear,
    selected,
    setSelected,
    clearSelected,
    isSelected,
    select,
    deselect,
    toggle,
    selectRange,
    deselectRange,
    dateStringToColor,
    calendar,
  }
}
