import { isDefined } from '@motion/utils/guards'
import { keys } from '@motion/utils/object'

import {
  Duration,
  type DurationLike,
  type DurationLikeObject,
  type DurationObjectUnits,
  type DurationUnit,
} from 'luxon'

import { templateStr } from '../utils'

export const MIN_DURATION_WITH_CHUNKS = 30
export const MIN_DURATION_DEFAULT_NO_CHUNKS_UNDER = 120
export const NO_CHUNK_DURATION = null
export const DEFAULT_DURATION = 30
export const NO_DURATION = null
export const SHORT_TASK_DURATION = 0

function normalizeMinDurations(min: number): DurationObjectUnits {
  return Duration.fromObject({
    hours: 0,
    minutes: min,
  })
    .normalize()
    .toObject()
}

export const formatDurationTime = (min: number | null) => {
  let { hours = 0, minutes = 0 } = normalizeMinDurations(min ?? 0)

  hours = Math.round(hours)
  minutes = Math.round(minutes)

  if (hours < 1) {
    return `${minutes} min`
  }

  if (minutes < 1) {
    return hours === 1 ? `1 hour` : `${hours} hours`
  }

  return `${hours}h ${minutes}m`
}

function getDurationFromDurationLikeValue(duration: DurationLike): Duration {
  if (typeof duration === 'number') {
    return Duration.fromObject({ minutes: duration })
  }

  if (duration instanceof Duration) {
    return duration
  }

  if (typeof duration === 'object') {
    return Duration.fromObject(duration as DurationLikeObject)
  }

  throw new Error('Invalid duration value')
}

const DURATION_ABBREVIATED_LABELS: Partial<Record<DurationUnit, string>> = {
  hours: 'h',
  minutes: 'm',
  seconds: 's',
} as const

const DURATION_UNITS = [...keys(DURATION_ABBREVIATED_LABELS)] as const

/**
 * Returns a formatted duration string in the format "1h 23m 45s"
 *
 * If any of the units are 0 or undefined, they will be omitted from the formatted string
 *
 * @param durationLikeValue - A duration like object (number, object or Duration object) to format.
 * @return The formatted duration string.
 */
export function formatDurationToShort(
  durationLikeValue: DurationLike | null
): string {
  if (durationLikeValue == null) return '0m'

  const duration = getDurationFromDurationLikeValue(durationLikeValue)
    .normalize()
    .shiftTo(...DURATION_UNITS)

  const formattedParts = DURATION_UNITS.map((unit) => {
    const value = Math.round(duration.get(unit) ?? 0)
    if (value > 0) {
      const label = DURATION_ABBREVIATED_LABELS[unit]
      return templateStr('{{value}}{{label}}', { value, label })
    }
    return ''
  }).filter(Boolean)

  if (formattedParts.length === 0) {
    return '0m'
  }

  return formattedParts.join(' ')
}

export function formatDurationToTime(totalMinutes: number | null) {
  const { hours = 0, minutes = 0 } = normalizeMinDurations(totalMinutes ?? 0)

  const hourText = hours.toString().padStart(2, '0')
  const minuteText = minutes.toString().padStart(2, '0')

  return `${hourText}:${minuteText}`
}

/**
 * Formats a duration in minutes to a short string.
 * For example, 30 minutes becomes "30m", 60 minutes becomes "1h", and 90 minutes becomes "1h 30m".
 * NO_DURATION (null) becomes "TBD" and SHORT_TASK_DURATION (0) becomes "Reminder".
 * @param minutes
 * @returns
 */
export function formatToShortTaskDuration(minutes: number | null) {
  if (minutes === NO_DURATION) return 'TBD'
  if (minutes === SHORT_TASK_DURATION) return 'Reminder'

  return formatDurationToShort(minutes)
}

/**
 * Formats a duration in minutes to a short string.
 * For example, 30 minutes becomes "30 min", 60 minutes becomes "1 hour", and 90 minutes becomes "1 hour 30 min".
 * NO_DURATION (null) becomes "To Be Determined" and SHORT_TASK_DURATION (0) becomes "Reminder".
 * @param minutes
 * @returns
 */
export function formatToTaskDuration(minutes: number | null) {
  if (minutes === NO_DURATION) return 'To Be Determined'
  if (minutes === SHORT_TASK_DURATION) return 'Reminder'

  return formatDurationTime(minutes)
}

const chunkDurations = [
  { label: 'No Chunks', value: NO_CHUNK_DURATION },
  ...[15, 30, 45, 60, 90, 120, 240, 480].map((time) => ({
    label: formatDurationTime(time),
    value: time,
  })),
]

const durations = [
  NO_DURATION,
  SHORT_TASK_DURATION,
  15,
  30,
  45,
  60,
  120,
  240,
  480,
  960,
]

export const timeDurations = durations.map((time) => ({
  label: formatDurationTime(time),
  value: time,
}))

const taskDurations = durations.map((time) => ({
  label: formatToTaskDuration(time),
  value: time,
}))

type DurationOptions = {
  includeNone?: boolean
}
export function getDurations(options: DurationOptions = {}) {
  const { includeNone = false } = options

  if (includeNone) return taskDurations

  return taskDurations.filter((d) => d.value !== NO_DURATION)
}

export const createDurationsFromText = (text: string) => {
  const choices = Array.from(new Set(parseDurationText(text))).filter(
    // Only allow anything under 16 hours (960 min)
    (time) => time <= 960
  )

  return choices.map((time) => ({
    label: formatDurationTime(time),
    value: time,
  }))
}

export function getChunkDurations(duration: number | null) {
  if (duration == null || duration < MIN_DURATION_WITH_CHUNKS) return []

  return chunkDurations.filter(
    (chunk) => chunk.value === NO_DURATION || chunk.value < duration
  )
}

export function getDefaultChunkDuration(duration: number | null) {
  if (duration === null || duration < MIN_DURATION_DEFAULT_NO_CHUNKS_UNDER) {
    return NO_CHUNK_DURATION
  }
  return 60
}

/**
 * Returns an array of possible durations in minutes.
 *
 * When no value can be ascertained, return an empty array
 *
 * if the value is ambiguous, return the number in both minutes and hours
 *
 *   `parseDurationText("2 hours") // [120]`
 *   `parseDurationText("2") // [2, 120]`
 */
export const parseDurationText = (text: string) => {
  const parts = text?.match(/\d+\.?\d*/g)?.map(parseFloat)
  const implyHours = text.includes('h')
  const implyMinutes = text.includes('m')

  if (!parts?.length) {
    return []
  }

  let minutes = 0
  let maybeHours

  if (parts.length === 1) {
    // if we didnt imply hours, we want to return minutes
    minutes = !implyHours ? parts[0] : parts[0] * 60

    // if we also didn't imply minutes, throw in a result for hours
    if (!implyMinutes && !implyHours) {
      maybeHours = parts[0] * 60
    }
  } else {
    minutes = parts[1] + parts[0] * 60
  }

  return [minutes, maybeHours].filter(isDefined).map(Math.floor)
}
