import {
  type Calendar,
  type CalendarEvent,
  CalendarProviderType,
} from '@motion/rpc/types'
import { keys } from '@motion/utils/object'
import { type CalendarEventSchemaV2 } from '@motion/zod/client'

import { DateTime } from 'luxon'

import { formatTime } from '../utils'

export type CalendarView = 'weekly' | 'daily'
export type CalendarStartDay = 'monday' | 'sunday'

export const calendarStartDayLabels: Record<CalendarStartDay, string> = {
  monday: 'Monday',
  sunday: 'Sunday',
}
export const calendarStartDayOptions = Object.keys(
  calendarStartDayLabels
) as CalendarStartDay[]

/**
 * ColorId 12 is not supported for setting on the calendar event instance for google.
 */
export const disabledGoogleEventColorIds: ColorId[] = ['12']

export const colorIdToName = {
  '0': 'Green',
  '1': 'Lavender',
  '2': 'Lime',
  '3': 'Purple',
  '4': 'Rose',
  '5': 'Yellow',
  '6': 'Orange',
  '7': 'Cyan',
  '8': 'Gray',
  '9': 'Blue',
  '10': 'Emerald',
  '11': 'Red',
  '12': 'Pink',
} as const

export const colorIds = keys(colorIdToName)
const colorClassIds = Array.from(colorIds)

export type ColorId = (typeof colorIds)[number]
export type ColorClassId = (typeof colorClassIds)[number]

export const colorClassIdToColorId: Record<ColorClassId, ColorId> = {
  ['0']: '9',
  ['1']: '10',
  ['2']: '3',
  ['3']: '7',
  ['4']: '2',
  ['5']: '11',
  ['6']: '8',
  ['7']: '5',
  ['8']: '0',
  ['9']: '1',
  ['10']: '4',
  ['11']: '6',
  ['12']: '12',
}

// invert the above keys with the values.
export const colorIdToColorClassId: Record<ColorId, ColorClassId> =
  colorClassIds.reduce(
    (obj, colorClassId) => {
      const colorId: ColorId = colorClassIdToColorId[colorClassId]
      obj[colorId] = colorClassId
      return obj
    },
    {} as Record<ColorId, ColorClassId>
  )

export function isColorId(arg: unknown): arg is ColorId {
  return (
    !!arg &&
    typeof arg === 'string' &&
    (colorIds as readonly string[]).includes(arg)
  )
}

export const getColorClassForColorId = (
  key: string | ColorId | undefined,
  defaultId: ColorId = '0'
): ColorClassId => {
  if (isColorId(key)) {
    return colorIdToColorClassId[key] ?? defaultId
  }

  return defaultId
}

export const getColorIdName = (colorId: ColorId) => {
  return colorIdToName[colorId] ?? 'Unknown'
}

export const formatTimeSlot = (date: DateTime, timezone?: string | null) => {
  const timeDate = timezone
    ? DateTime.fromMillis(date.toMillis(), { zone: timezone })
    : date

  return formatTime(timeDate, { optionalMinutes: true })
}

function eventIsForCalendar(
  event: Pick<CalendarEvent, 'calendarId'>,
  calendar: Calendar
): boolean {
  return (
    event.calendarId === calendar.id || calendar.providerId === event.calendarId
  )
}

export function calendarHasEditableRole(calendar: Calendar): boolean {
  return calendar.accessRole === 'OWNER' || calendar.accessRole === 'EDITOR'
}

export function isCalendarEventEditable(
  event: Pick<
    CalendarEvent,
    | 'id'
    | 'providerId'
    | 'attendees'
    | 'email'
    | 'calendarId'
    | 'type'
    | 'organizer'
  >,
  calendar: Calendar,
  hostEmail?: string
): boolean {
  const curUserAttendee = event.attendees?.find((a) => a.email === event.email)
  const curUserIsOrganizer = curUserAttendee?.isOrganizer ?? false

  return (
    eventIsForCalendar(event, calendar) &&
    calendarHasEditableRole(calendar) &&
    ((!!hostEmail && event.organizer?.email === hostEmail) ||
      calendar.providerType === CalendarProviderType.APPLE ||
      !event.attendees ||
      !event.attendees.length ||
      curUserIsOrganizer) &&
    event.type !== 'RECURRING_EVENT'
  )
}

export function isHostOfEvent(
  event?: Pick<CalendarEventSchemaV2, 'attendees' | 'organizer' | 'email'>
) {
  if (!event) return false

  return Boolean(
    (event.organizer && event.organizer.email === event.email) ||
      (event.attendees &&
        event.attendees.find(
          (attendee) => attendee.isOrganizer && attendee.email === event.email
        ))
  )
}
