import type { ProjectSchema } from '@motion/zod/client'

import { DateTime } from 'luxon'

/**
 * Start of stage is start of project for first stage or previous stage's due date
 */
export function getFlowTaskStartDate(
  selectedStageIndex: number,
  project: ProjectSchema
): string {
  const today = DateTime.now().startOf('day').toISODate()

  if (selectedStageIndex === 0) {
    if (project.startDate != null) {
      return project.startDate
    }

    return today
  }

  const previousStage = project.stages[selectedStageIndex - 1]

  if (previousStage?.dueDate != null) {
    return previousStage.dueDate
  }

  return today
}
