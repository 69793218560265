import {
  useSharedState,
  useSharedStateSendOnly,
} from '@motion/react-core/shared-state'
import { API, createFetch, createQueryOptions } from '@motion/rpc'

import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'

import { prefetchQueries, type QueryReference } from './prefetch'
import {
  AuthStateKey,
  AuthTokenKey,
  UNSET_SIGNOUT,
  UserLoginStateKey,
} from './state'

import { bus } from '../event-bus'
import { firebase, getRedirectResult, type User } from '../firebase'
import { featureFlags } from '../flags'
import { makeLog } from '../logging'
import { stats } from '../performance'
import { STATIC_HEADERS } from '../rpc/constants'
import { Sentry } from '../sentry'
import { SettingsQueryKey } from '../settings'
import { DB } from '../storage'
import { SubscriptionStateKey } from '../subscriptions/state'
import { buildSubscriptionState } from '../subscriptions/utils'

const log = makeLog('firebase-auth')

function shouldCallOnLogin(urlPath: string) {
  if (isDesktop()) return false
  return true
}

export const FirebaseAuthSync = () => {
  const [authState, setAuthState] = useSharedState(AuthStateKey)
  const setAuthToken = useSharedStateSendOnly(AuthTokenKey)
  const setUserLoginState = useSharedStateSendOnly(UserLoginStateKey)
  const setUserSubscription = useSharedStateSendOnly(SubscriptionStateKey)

  const client = useQueryClient()

  if (authState.signOut === UNSET_SIGNOUT) {
    authState.signOut = async () => {
      Sentry.addBreadcrumb({ message: 'logout' })
      setAuthState((prev) => ({ ...prev, auth: { state: 'signing-out' } }))
      await DB.clearAll().catch((ex) => {
        Sentry.captureException(ex, {
          tags: { position: 'IndexedDB.clearAll' },
        })
      })
      await authState.event.fireAsync({ state: 'signing-out' })
      await firebase.auth().signOut()
      setAuthState((prev) => ({ ...prev, auth: { state: 'unauthenticated' } }))
      client.clear()

      const postLogout = createFetch(API.users.postLogoutApi, {
        token: null,
        baseUri: __BACKEND_HOST__,
        headers: STATIC_HEADERS,
      })
      await postLogout()
    }
  }

  useEffect(
    function refreshUser() {
      return firebase.auth().onAuthStateChanged(async (user) => {
        log('user-changed', { user })
        if (user) {
          // don't call post-login for opt-space
          // TODO: this will need to be changes when we clean up startup
          if (!shouldCallOnLogin(window.location.pathname)) {
            setAuthState((prev) => ({
              ...prev,
              auth: { state: 'authenticated', user: user as User },
            }))
            bus.emit('auth:user-changed', { user })
            return
          }

          setAuthState((prev) => ({
            ...prev,
            auth: { state: 'authenticating' },
          }))

          const token = await log.time(
            'get-token',
            () => user.getIdTokenResult(true),
            (value) => value
          )

          const queryContext = {
            token: token.token,
            baseUri: __BACKEND_HOST__,
            headers: STATIC_HEADERS,
            client,
          }

          function prefetch(query: QueryReference) {
            const queryOptions = createQueryOptions(
              query.query,
              queryContext
            )(query.args)
            return client.ensureQueryData(queryOptions)
          }

          const postLogin = createFetch(API.users.postLoginApi, {
            token: token.token,
            baseUri: __BACKEND_HOST__,
            headers: STATIC_HEADERS,
          })

          log('calling on-login')

          const prefetchStart = stats.mark('prefetch.start')

          await stats.time('prefetch', () =>
            Promise.all([
              featureFlags.identify(user.email),
              ...prefetchQueries.duringAuth.map(prefetch),
              withRetry(() => postLogin())
                .then((data) => {
                  log('on-login complete')
                  setUserLoginState(
                    data.state ?? { hasOldAutoScheduledTasks: false }
                  )

                  setUserSubscription(buildSubscriptionState(data.subscription))
                  client.setQueryData(
                    API.subscriptions.getIndividualAndTeamSubscription.key(),
                    data.subscription
                  )

                  client.setQueryData(SettingsQueryKey, data.firestore)

                  setAuthState((prev) => ({
                    ...prev,
                    auth: { state: 'authenticated', user: user as User },
                  }))

                  bus.emit('auth:user-changed', { user })
                  return data
                })
                .then(async (loginData) => {
                  stats.measure('prefetch.login', prefetchStart.name)

                  await stats.time('prefetch.post-login', () =>
                    Promise.all(prefetchQueries.postAuth.map(prefetch))
                  )

                  return loginData
                })
                .catch((ex) => {
                  log('on-login failed', ex)
                  Sentry.captureException(
                    new Error('on-login failed', { cause: ex }),
                    { tags: { position: 'on-login' } }
                  )
                  setAuthState((prev) => ({
                    ...prev,
                    auth: { state: 'error', error: ex },
                  }))
                  bus.emit('auth:user-changed', { user: null })
                }),
            ])
          )
        } else {
          setAuthState((prev) => {
            if (prev.auth.state === 'signing-out') return prev
            prev.signOut().catch((ex) => void 0)
            return prev
          })
          await featureFlags.identify(null)
          bus.emit('auth:user-changed', { user })
        }
      })
    },
    [client, setAuthState, setUserLoginState, setUserSubscription]
  )

  useEffect(function checkRedirectLogin() {
    void getRedirectResult(firebase.auth()).then((result) => {
      if (result) {
        log('redirect-result', result)
        bus.emit('auth:redirect-result', { result })
      }
      return result
    })
  }, [])

  useEffect(
    function refreshToken() {
      return firebase.auth().onIdTokenChanged(async (user) => {
        if (user == null) {
          log('token-changed', { token: null })
          return
        }
        const token = await user.getIdToken()
        log('token-changed', { token })

        bus.emit('auth:token-changed', { token })

        setAuthToken(token)
      })
    },
    [setAuthToken]
  )

  return null
}

function isDesktop() {
  return window.location.pathname === '/web/desktop'
}

type RetryOptions = {
  retries: number
  delay: number[]
}

async function withRetry<T>(
  fn: () => Promise<T>,
  opts: RetryOptions = { retries: 3, delay: [100, 500, 1000] }
) {
  let retries = 0
  while (true) {
    try {
      // eslint-disable-next-line no-await-in-loop
      return await fn()
    } catch (ex) {
      if (retries >= opts.retries) {
        log('retries exceeded')
        throw ex
      }

      const wait = opts.delay[Math.min(retries, opts.delay.length - 1)]
      log(`on-login failed. retrying in ${wait}ms`, { ex })
      // eslint-disable-next-line no-await-in-loop
      await delay(wait)
    } finally {
      retries++
    }
  }
}

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
