import {
  type Calendar,
  type CalendarEvent,
  type CalendarList,
  type CalendarProviderType,
  type Contact,
  type DeprecatedCalendar,
  type EmailAccount,
  type EventAttendee,
  EventConferenceTypeHumanReadable,
  LimitedEventConferenceTypes,
} from '@motion/rpc/types'
import { EventConferenceType } from '@motion/shared/common'

export function getEventOrganizer(event: Partial<CalendarEvent>) {
  if (event.organizer) {
    return event.organizer.displayName || event.organizer.email
  }

  return event.email ?? ''
}

export function getCalendarForEvent(
  event: CalendarEvent,
  calendarList: CalendarList
) {
  const calendars = calendarList[event.email] ?? []
  return calendars.find((c) => c.id === event.calendarId)
}

export function getIsEventEditable(
  event: CalendarEvent,
  attendee?: EventAttendee,
  calendar?: DeprecatedCalendar
) {
  if (!event.id) {
    return false
  }

  const isEventOrganizer =
    !event.attendees ||
    !event.attendees.length ||
    attendee?.isOrganizer ||
    event.organizer?.email === calendar?.email

  return Boolean(calendar?.editable && isEventOrganizer)
}

export function getConferenceTypesForProviderType(
  providerType: CalendarProviderType
) {
  return LimitedEventConferenceTypes.filter((type) => {
    if (type === 'hangoutsMeet') {
      return providerType === 'GOOGLE'
    } else if (type === 'teamsForBusiness') {
      return providerType === 'MICROSOFT'
    } else if (type === 'meet') {
      return false
    }
    return true
  })
}

const commonConferenceTypes = ['none', 'zoom', 'phone', 'customLocation']
const googleOnlyConferenceTypes = ['meet', 'hangoutsMeet', 'teamsForBusiness']
const msOnlyConferenceTypes = ['teamsForBusiness']
export function isValidConferenceTypeForProviderType(
  conferenceType: EventConferenceType,
  providerType: CalendarProviderType
) {
  if (commonConferenceTypes.includes(conferenceType)) {
    return true
  }
  if (
    providerType === 'GOOGLE' &&
    googleOnlyConferenceTypes.includes(conferenceType)
  ) {
    return true
  }
  if (
    providerType === 'MICROSOFT' &&
    msOnlyConferenceTypes.includes(conferenceType)
  ) {
    return true
  }
  return false
}

type GetConferenceTypeFromLinkArgs = {
  conferenceLink: string | null | undefined
  readable?: boolean
}

export function getConferenceTypeFromConferenceLink({
  conferenceLink,
  readable,
}: GetConferenceTypeFromLinkArgs & {
  readable: true
}): EventConferenceTypeHumanReadable
export function getConferenceTypeFromConferenceLink({
  conferenceLink,
  readable,
}: GetConferenceTypeFromLinkArgs & {
  readable?: false
}): EventConferenceType
export function getConferenceTypeFromConferenceLink({
  conferenceLink,
  readable,
}: GetConferenceTypeFromLinkArgs): unknown {
  const typeDict = readable
    ? EventConferenceTypeHumanReadable
    : EventConferenceType

  if (!conferenceLink) return typeDict.none
  if (conferenceLink.includes('zoom.us')) {
    return typeDict.zoom
  } else if (conferenceLink.includes('meet.google.com')) {
    return typeDict.hangoutsMeet
  } else if (conferenceLink.includes('teams.microsoft.com')) {
    return typeDict.teamsForBusiness
  } else if (conferenceLink.includes('join.skype.com')) {
    return typeDict.skypeForConsumer
  }
  return typeDict.none
}

export const isMainCalendar = (
  email: string,
  mainCalendarEmail: string,
  isPrimary: boolean
) => email === mainCalendarEmail && isPrimary

export function getMainCalendars(calendarList: CalendarList) {
  const mainCalendars: DeprecatedCalendar[] = []
  const mainCalendarEmails = Object.keys(calendarList)

  mainCalendarEmails.forEach((email) => {
    const calendars = calendarList[email]
    const mainCalendar = calendars.find((c) => c.primary && c.email === email)
    if (mainCalendar) {
      mainCalendars.push(mainCalendar)
    }
  })

  return mainCalendars
}

export function isCalendarRemovableFromMyCalendars(
  calendar: Pick<Calendar, 'emailAccountId' | 'isInMyCalendars' | 'isPrimary'>,
  mainEmailAccountId: string | undefined | null
) {
  if (mainEmailAccountId == null) return false
  const isMainAccountPrimaryCalendar =
    calendar.emailAccountId === mainEmailAccountId && !!calendar.isPrimary
  return !isMainAccountPrimaryCalendar
}

export function isCalendarRemovableFromAccount(
  calendar: Pick<Calendar, 'isInMyCalendars' | 'isPrimary'>
) {
  return !calendar.isPrimary && !calendar.isInMyCalendars
}

type CalendarSortProps = Pick<
  Calendar,
  'emailAccountId' | 'isPrimary' | 'title'
>

export function sortCalendars<T extends CalendarSortProps>(
  calendars: T[],
  mainEmailAccountId: string | undefined
) {
  return calendars.sort((a, b) => {
    // Main calendar emails should be at the top
    if (
      a.emailAccountId === mainEmailAccountId &&
      b.emailAccountId !== mainEmailAccountId
    )
      return -1
    if (
      b.emailAccountId === mainEmailAccountId &&
      a.emailAccountId !== mainEmailAccountId
    )
      return 1

    // Primary calendars should also be at the top
    if (a.isPrimary && !b.isPrimary) return -1
    if (b.isPrimary && !a.isPrimary) return 1

    return a.title.localeCompare(b.title)
  })
}

export function findCalendarForContact(
  contact: Contact,
  emailAccounts: EmailAccount[],
  calendars: Calendar[]
): Calendar | undefined {
  if (!contact.account) {
    return undefined
  }

  const emailAccount = emailAccounts.find((e) => e.email === contact.account)
  if (!emailAccount) {
    return undefined
  }

  return calendars.find(
    (c) =>
      c.providerId === contact.email && c.emailAccountId === emailAccount.id
  )
}
type CalendarOption = Pick<
  Calendar,
  | 'id'
  | 'accessRole'
  | 'deletedTime'
  | 'isPrimary'
  | 'emailAccountId'
  | 'providerId'
>

export function getEditableCalendars<T extends CalendarOption>(calendars: T[]) {
  return calendars
    .filter(
      (c) =>
        !c.deletedTime &&
        (c.isPrimary || c.accessRole === 'OWNER' || c.accessRole === 'EDITOR')
    )
    .sort((a, b) => {
      if (a.isPrimary && !b.isPrimary) {
        return -1
      } else if (!a.isPrimary && b.isPrimary) {
        return 1
      }
      return 0
    })
}
