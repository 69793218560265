import { StageAdjuster } from '@motion/shared/flows'
import { parseDate } from '@motion/utils/dates'
import { type ProjectSchema, type StageSchema } from '@motion/zod/client'

/*
 * Given a list of stages, and a project start and due date, return the stages with the correct stage start and end dates
 */
export function getStagesWithDates<T extends { dueDate: string }>(
  stages: T[],
  { start, due }: { start: string | null; due: string | null }
): { start: string; due: string; stage: T }[] {
  // If there is a start date provided, use that as the start date for the first stage, otherwise use the stage due date
  const getStageStart = (index: number) => {
    if (index === 0) {
      return start ?? stages[index].dueDate
    }

    return stages[index - 1].dueDate
  }

  // If there is a due date provided, use that as the due date for the last stage
  const getStageEnd = (index: number) => {
    if (index === stages.length - 1 && due) {
      return due
    }

    return stages[index].dueDate
  }

  return stages.map((stage, index) => {
    const stageStart = getStageStart(index)
    const stageEnd = getStageEnd(index)

    return {
      stage,
      start: parseDate(stageStart).toISODate(),
      due: parseDate(stageEnd).toISODate(),
    }
  })
}

export type StageWithDates = {
  start: string
  due: string
  stage: Omit<
    StageSchema,
    | 'visited'
    | 'skipped'
    | 'completedTime'
    | 'canceledTime'
    | 'completedDuration'
    | 'canceledDuration'
  >
}

const organizeStages = (
  mergedStagesWithDates: StageWithDates[],
  previousStagesWithDates: StageWithDates[]
) => {
  // Organize stages into changed and unchanged
  return mergedStagesWithDates.reduce(
    (acc, stageWithDates, index) => {
      let maybeUpdatedStage = stageWithDates
      let previousStage = previousStagesWithDates[index]

      if (
        maybeUpdatedStage.start !== previousStage.start ||
        maybeUpdatedStage.due !== previousStage.due
      ) {
        acc.changed.push(maybeUpdatedStage)
      } else {
        acc.unchanged.push(maybeUpdatedStage)
      }

      return acc // Add this line to return the accumulator
    },
    {
      unchanged: [],
      changed: [],
    } as Record<'unchanged' | 'changed', StageWithDates[]>
  )
}

export function getUpdatedStages({
  project,
  startDate,
  dueDate,
}: {
  project: Pick<
    ProjectSchema,
    'startDate' | 'dueDate' | 'stages' | 'activeStageDefinitionId'
  >
  startDate?: string
  dueDate?: string
}) {
  let stagesWithDates: StageWithDates[] = []
  let updatedStages: Record<'unchanged' | 'changed', StageWithDates[]> = {
    unchanged: [],
    changed: [],
  }

  const adjustedStages = new StageAdjuster({
    start: project.startDate,
    due: project.dueDate,
    stages: project.stages.map((stage) => ({
      ...stage,
      active: stage.stageDefinitionId === project.activeStageDefinitionId,
    })),
  })
    .adjust({ start: startDate, due: dueDate })
    .map((stage) => ({
      ...stage,
      id: stage.stageDefinitionId,
      dueDate: stage.dueDate.toISO(),
    }))

  stagesWithDates = getStagesWithDates(adjustedStages, {
    start: startDate || project.startDate,
    due: dueDate || project.dueDate,
  })

  const previousStagesWithDates = getStagesWithDates(project.stages, {
    start: project.startDate,
    due: project.dueDate,
  })

  updatedStages = organizeStages(stagesWithDates, previousStagesWithDates)

  return {
    stagesWithDates,
    updatedStages,
  }
}
