import { type DateLike, parseDate } from '@motion/utils/dates'

import { DateTime } from 'luxon'

import { formatMonthDay, formatToReadableWeekDayMonth } from './format-date'

import { templateStr } from '../string'

/**
 * Returns a formatted time string representing a time range of `from - to`
 * The minutes will be omitted if they are 00.
 * And the meridiem of the "from" will be omitted if it matches the "to" meridiem
 *
 * @param {DateLike} time - The "from" date like object to format.
 * @param {DateLike} time - The "to" date like object to format.
 * @return {string} The formatted time string.
 */
export const formatTimeRange = (
  from: DateLike,
  to: DateLike,
  zone?: string
) => {
  const parsedFrom = parseDate(from, zone)
  const parsedTo = parseDate(to, zone)

  const fromFormatted = formatTime(parsedFrom, { optionalMinutes: true })
  const toFormatted = formatTime(parsedTo, { optionalMinutes: true })
  const toMeridiem = parsedTo.toFormat('a')

  return templateStr('{{from}} - {{to}}', {
    from: toMeridiem
      ? removeMeridiem(fromFormatted, toMeridiem)
      : fromFormatted,
    to: toFormatted,
  })
}

/**
 * Returns a formatted time string representing a time range of `from - to`
 * eg. Tue Dec 4, 1 - 2 PM
 * eg. Tue Dec 4, 1 PM -  Thu Dec 6, 12 PM
 *
 * The minutes will be omitted if they are 00.
 * And the meridiem of the "from" will be omitted if it matches the "to" meridiem
 *
 * @param {DateLike} date - The date like object to format.
 * @return {string} The formatted date string.
 */
export const formatDateTimeRange = (from: DateLike, to: DateLike) => {
  const parsedFrom = parseDate(from)
  const parsedTo = parseDate(to)

  const fromDate = formatToReadableWeekDayMonth(parsedFrom)
  const fromTime = formatTime(parsedFrom, { optionalMinutes: true })

  const toDate = formatToReadableWeekDayMonth(parsedTo)
  const toTime = formatTime(parsedTo, { optionalMinutes: true })
  const toMeridiem = parsedTo.toFormat('a')

  return [
    [fromDate, removeMeridiem(fromTime, toMeridiem)].filter(Boolean).join(', '),
    [toDate !== fromDate ? toDate : undefined, toTime]
      .filter(Boolean)
      .join(', '),
  ].join(' - ')
}

/**
 * Returns a formatted date time string with medium date style and long time style, based on the locale.
 * In the US locale, the format would be "Dec 4, 2023 12:45:10 PM MDT"
 * @param {DateLike} time - A date like object (string, number, Date or DateTime object) to format.
 * @return {string} The formatted time string.
 */
export function formatDateTimeDetailed(time: DateLike) {
  return parseDate(time).toLocaleString({
    dateStyle: 'medium',
    timeStyle: 'long',
  })
}

/**
 * Returns a formatted date time string with a medium date style conditionally displaying the year, and a short time style.
 * @param {DateLike} datetime - A date like object (string, number, Date or DateTime object) to format.
 * @param {string} [separator] - The separator string to use between the date and time.
 * @return {string} The formatted time string.
 * @example
 * formatDateTime(new Date('2024-12-04T12:45:10')) // "Dec 4 at 12:45 PM" (current year)
 * @example
 * formatDateTime(new Date('2022-12-04T12:45:10')) // "Dec 4, 2022 at 12:45 PM"
 */
export function formatDateTime(datetime: DateLike, separator = ' at ') {
  return formatMonthDay(datetime) + separator + formatTime(datetime)
}

/**
 * Returns a formatted time string without minutes when it's the exact hour
 *
 * @param {DateLike} time - A date like object (string, number, Date or DateTime object) to format.
 * @return {string} The formatted time string.
 */
interface FormatTimeOptions {
  optionalMinutes?: boolean
}
export function formatTime(time: DateLike, options: FormatTimeOptions = {}) {
  const { optionalMinutes = false } = options

  const formattedTime = parseDate(time).toLocaleString(DateTime.TIME_SIMPLE)

  if (optionalMinutes) return maybeRemoveMinutesFromTime(formattedTime)

  return formattedTime
}

const maybeRemoveMinutesFromTime = (timeString: string) =>
  timeString.replace(':00', '')

const removeMeridiem = (timeString: string, meridiem: string) =>
  timeString.replace(meridiem, '').trimEnd()

type RelativeTimeOptions = {
  cutoffSeconds: number
}
/**
 * Returns a relative time string.
 * Anything under 60 seconds will return "just now", but this cutoff is customizable via the options
 *
 * @param {DateLike} time - A date like object (string, number, Date or DateTime object) to format.
 * @param {Partial<RelativeTimeOptions>} options - options customizing the formatting
 * @return {string} The formatted relative time string.
 */
export function formatToRelativeTime(
  time: DateLike,
  options: Partial<RelativeTimeOptions> = {}
) {
  const { cutoffSeconds = 60 } = options
  const date = parseDate(time)
  const now = DateTime.now()
  const diffInSeconds = Math.abs(now.diff(date, 'seconds').seconds)

  if (diffInSeconds < cutoffSeconds) {
    return 'just now'
  }

  return date.toRelative()
}
