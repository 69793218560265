import {
  getSegmentAnalytics,
  getTrackingCookies,
  recordAnalyticsEvent,
} from '@motion/web-common/analytics'
import { useExperiment } from '@motion/web-common/flags'

export const useEnterpriseLink = () => {
  let enterpriseLink = useExperiment('harry-enterprise-booking-link').payload
    ?.url

  const isInvalidLink = !enterpriseLink

  if (!enterpriseLink) {
    enterpriseLink =
      'https://app.usemotion.com/enterprise/antonio-garcia/sales?d=30'
  }

  return (location: string, userEmail?: string) => {
    const segmentAnalytics = getSegmentAnalytics()
    if (segmentAnalytics) {
      const cookieData = getTrackingCookies()
      getSegmentAnalytics()?.track(
        'ENTERPRISE_CARD_CLICKED',
        {
          url: enterpriseLink,
          isInvalidLink,
          location,
          ...cookieData,
        },
        {
          email: userEmail,
        }
      )
      if (userEmail) {
        getSegmentAnalytics()?.track(
          'ENTERPRISE_WORK_EMAIL_SUBMITTED',
          {
            email: userEmail,
            location,
            ...cookieData,
          },
          {
            email: userEmail,
          }
        )
      }
    } else {
      recordAnalyticsEvent('ENTERPRISE_CARD_CLICKED', {
        url: enterpriseLink,
        isInvalidLink,
        location,
      })
    }

    if (userEmail) {
      const url = new URL(enterpriseLink)
      url.searchParams.set('userEmail', userEmail)
      window.open(url.toString(), '_blank')
    } else {
      window.open(enterpriseLink, '_blank')
    }
  }
}
