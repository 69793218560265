import {
  RTEBoldSolid,
  RTECheckListSolid,
  RTECodeblockOutline,
  RTEH1Solid,
  RTEH2Solid,
  RTEImageSolid,
  RTEItalicizedSolid,
  RTELinkSolid,
  RTEOrderedListSolid,
  RTEStrikethroughSolid,
  RTEUnderlineSolid,
  RTEUnorderedListSolid,
} from '@motion/icons'
import { IconButton, type IconButtonProps } from '@motion/ui/base'

import { type Editor } from '@tiptap/react'
import { useRef } from 'react'

export type RichTextToolbarProps = {
  editor: Editor | null
  disabled?: boolean
  onFileUpload?: (file: File) => Promise<string | undefined>
  onLinkClick: (initialLink?: string | undefined) => Promise<string | null>
  disableImageUpload?: boolean
  size?: 'small' | 'normal'
}

export function RichTextToolbar(props: RichTextToolbarProps) {
  const {
    editor,
    onFileUpload,
    onLinkClick,
    disabled = false,
    disableImageUpload = false,
    size,
  } = props

  const fileInputRef = useRef<HTMLInputElement>(null)

  if (!editor) {
    return null
  }

  const handleChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | undefined = e.target.files?.[0]

    if (!editor || !onFileUpload || !file) return

    const url = await onFileUpload?.(file)

    if (url != null) {
      editor.chain().focus().setImage({ src: url, title: file.name }).run()
    }
  }

  const handleClickLink = async () => {
    if (!editor) return

    const previousUrl = editor.getAttributes('link').href

    const url = await onLinkClick(previousUrl)

    if (url != null) {
      if (url === '') {
        // Remove link
        editor.chain().focus().extendMarkRange('link').unsetLink().run()
        return
      }

      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: !url.includes('://') ? `https://${url}` : url })
        .run()
    }
  }

  return (
    <div className='flex flex-wrap gap-2'>
      <div className='flex'>
        <ToolbarButton
          icon={RTEBoldSolid}
          pressed={editor.isActive('bold')}
          disabled={disabled}
          onClick={() => editor.chain().focus().toggleBold().run()}
          size={size}
        />
        <ToolbarButton
          icon={RTEItalicizedSolid}
          pressed={editor.isActive('italic')}
          disabled={disabled}
          onClick={() => editor.chain().focus().toggleItalic().run()}
          size={size}
        />
        <ToolbarButton
          icon={RTEUnderlineSolid}
          pressed={editor.isActive('underline')}
          disabled={disabled}
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          size={size}
        />
        <ToolbarButton
          icon={RTEStrikethroughSolid}
          pressed={editor.isActive('strike')}
          disabled={disabled}
          onClick={() => editor.chain().focus().toggleStrike().run()}
          size={size}
        />
      </div>
      <div className='flex'>
        <ToolbarButton
          icon={RTEH1Solid}
          pressed={editor.isActive('heading', { level: 1 })}
          disabled={disabled}
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
          size={size}
        />
        <ToolbarButton
          icon={RTEH2Solid}
          pressed={editor.isActive('heading', { level: 2 })}
          disabled={disabled}
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          size={size}
        />
      </div>
      <div className='flex'>
        <ToolbarButton
          icon={RTEUnorderedListSolid}
          pressed={editor.isActive('bulletList')}
          disabled={disabled}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          size={size}
        />
        <ToolbarButton
          icon={RTEOrderedListSolid}
          pressed={editor.isActive('orderedList')}
          disabled={disabled}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          size={size}
        />
        <ToolbarButton
          icon={RTECheckListSolid}
          pressed={editor.isActive('taskList')}
          disabled={disabled}
          onClick={() => editor.chain().focus().toggleTaskList().run()}
          size={size}
        />
      </div>
      <div className='flex'>
        {!disableImageUpload && (
          <ToolbarButton
            icon={RTEImageSolid}
            disabled={disabled}
            onClick={() => fileInputRef?.current?.click()}
            size={size}
          />
        )}
        <ToolbarButton
          icon={RTECodeblockOutline}
          pressed={editor.isActive('code')}
          disabled={disabled}
          onClick={() => editor.chain().focus().toggleCode().run()}
          size={size}
        />
        <ToolbarButton
          icon={RTELinkSolid}
          pressed={editor.isActive('link')}
          disabled={disabled}
          onClick={handleClickLink}
          size={size}
        />
      </div>

      {!disableImageUpload && (
        <input
          className='hidden'
          ref={fileInputRef}
          type='file'
          onChange={handleChangeFile}
        />
      )}
    </div>
  )
}

type ToolbarButtonProps = {
  icon: IconButtonProps['icon']
  pressed?: boolean
  disabled?: boolean
  onClick: IconButtonProps['onClick']
  size?: IconButtonProps['size']
}

function ToolbarButton({ icon, ...rest }: ToolbarButtonProps) {
  return (
    <IconButton icon={icon} variant='muted' sentiment='neutral' {...rest} />
  )
}
