import { createNoneId } from '@motion/shared/identifiers'
import {
  type NormalTaskSchema,
  type TaskDefinitionSchema,
  type TaskResponseSchema,
  type TaskSchema,
  type VariableDefinitionSchema,
  type VariableInstanceSchema,
} from '@motion/zod/client'

import { DateTime } from 'luxon'

import { replaceTextVariables } from '../utils'

type GhostTaskCheckableType =
  | Pick<TaskSchema, 'id' | 'isUnvisitedStage'>
  | Pick<TaskResponseSchema, 'id' | 'isUnvisitedStage'>
  | { id: string; isUnvisitedStage?: boolean }

export const isGhostTask = (task: GhostTaskCheckableType) =>
  isInUnvisitedStage(task) || isLegacyGhostTask(task.id)

const createLegacyGhostTaskIdFromTaskDefinitionId = (taskDefId: string) =>
  `${taskDefId}|<ghost>`
export const isLegacyGhostTask = (id: string) => id.endsWith('|<ghost>')

export const isInUnvisitedStage = (task: GhostTaskCheckableType) =>
  task.isUnvisitedStage ?? false

type GhostTaskParams = {
  stageDueDate: string
  workspaceId: string
  projectId: string
  projectName: string
  variables: VariableDefinitionSchema[]
  variablesValues: Record<string, VariableInstanceSchema>
}
export const createGhostTask = (
  taskDefinition: TaskDefinitionSchema,
  params: GhostTaskParams
): NormalTaskSchema => {
  const {
    stageDueDate,
    workspaceId,
    projectId,
    projectName,
    variables,
    variablesValues,
  } = params

  const taskName = replaceTextVariables(
    taskDefinition.name,
    variables,
    variablesValues,
    { projectName }
  )
  const taskDescription = replaceTextVariables(
    taskDefinition.description,
    variables,
    variablesValues,
    { projectName }
  )

  const assigneeVariableId =
    variables.find((v) => v.key === taskDefinition.assigneeVariableKey)?.id ??
    null
  const realAssigneeId =
    taskDefinition.assigneeUserId ??
    (assigneeVariableId != null
      ? variablesValues[assigneeVariableId]?.value ?? null
      : null)

  const startOn = DateTime.fromISO(stageDueDate)
    .minus({ minute: taskDefinition.duration ?? 0 })
    .toISODate()

  return {
    type: 'NORMAL',
    workspaceId,
    projectId,
    ...taskDefinition,
    id: createLegacyGhostTaskIdFromTaskDefinitionId(taskDefinition.id),
    name: taskName,
    description: taskDescription,
    assigneeUserId: realAssigneeId,

    startDate: startOn,
    startOn,
    dueDate: stageDueDate,
    deadlineType: 'SOFT',
    scheduleId: 'work',
    scheduledStart: null,
    scheduledEnd: null,
    createdByUserId: createNoneId('unknown'),
    completedDuration: 0,
    completedTime: null,
    createdTime: DateTime.utc().toISO(),
    updatedTime: null,
    archivedTime: null,
    rank: null,
    isBusy: false,
    isFixedTimeTask: false,
    isUnfit: false,
    needsReschedule: false,
    scheduleOverridden: false,
    snoozeUntil: null,
    manuallyStarted: false,
    isFutureSchedulable: false,
    ignoreWarnOnPastDue: false,
    chunkIds: [],
    blockingTaskIds: [],
    blockedByTaskIds: [],
    customFieldValues: {},
    scheduledStatus: null,
    estimatedCompletionTime: null,
    stageDefinitionId: null,
    taskDefinitionId: taskDefinition.id,
    isSyncingWithDefinition: true,
    isUnvisitedStage: true,
  } satisfies NormalTaskSchema
}
