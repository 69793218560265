import { createKey, defineApi, defineMutation } from '../../core'
import { type RouteTypes } from '../types'

export const queryKeys = {
  root: createKey(['v2', 'workspaces']),
  query: (args: GetAppWorkspaceContext['request']) =>
    createKey(queryKeys.root, args as any),
}

type GetAppWorkspaceContext = RouteTypes<'WorkspacesController_getContexts'>
export const getWorkspaces = defineApi<
  GetAppWorkspaceContext['request'],
  GetAppWorkspaceContext['response']
>().using({
  key: (args) => queryKeys.query(args),
  uri: `/v2/workspaces/query`,
  method: 'POST',
})

type CreateWorkspace = RouteTypes<'WorkspacesController_createWorkspace'>
export const createWorkspace = defineMutation<
  CreateWorkspace['request'],
  CreateWorkspace['response']
>().using({
  method: 'POST',
  uri: '/v2/workspaces',
})

type UpdateWorkspace = RouteTypes<'WorkspacesController_updateWorkspace'>
export const updateWorkspace = defineMutation<
  UpdateWorkspace['request'],
  UpdateWorkspace['response']
>().using({
  method: 'PATCH',
  uri: (args) => `/v2/workspaces/${args.workspaceId}`,
  body: ({ workspaceId, ...args }) => args,
})

type DeleteWorkspace = RouteTypes<'WorkspacesController_delete'>
export const deleteWorkspace = defineMutation<
  DeleteWorkspace['request'],
  void
>().using({
  method: 'DELETE',
  uri: (args) => `/v2/workspaces/${args.workspaceId}`,
})

type CreateWorkspaceMember =
  RouteTypes<'WorkspacesController_addUserToWorkspace'>
export const createWorkspaceMember = defineMutation<
  CreateWorkspaceMember['request'],
  CreateWorkspaceMember['response']
>().using({
  method: 'POST',
  uri: (args) => `/v2/workspaces/${args.workspaceId}/members`,
  body: ({ workspaceId, ...args }) => args,
})

type UpdateWorkspaceMember =
  RouteTypes<'WorkspacesController_updateWorkspaceMember'>
export const updateWorkspaceMember = defineMutation<
  UpdateWorkspaceMember['request'],
  UpdateWorkspaceMember['response']
>().using({
  method: 'PATCH',
  uri: (args) => `/v2/workspaces/${args.workspaceId}/members/${args.memberId}`,
  body: ({ workspaceId, memberId, ...args }) => args,
})

type DeleteWorkspaceMember =
  RouteTypes<'WorkspacesController_removeWorkspaceMemberFromWorkspace'>
export const deleteWorkspaceMember = defineMutation<
  DeleteWorkspaceMember['request'],
  DeleteWorkspaceMember['response']
>().using({
  method: 'DELETE',
  uri: (args) => `/v2/workspaces/${args.workspaceId}/members/${args.memberId}`,
  body: ({ workspaceId, memberId, ...args }) => args,
})
