import { type NodeViewProps, NodeViewWrapper } from '@tiptap/react'

import { RteImage } from '../components'

export function CustomImageView(props: NodeViewProps) {
  const handleExpand = () => {
    props.extension.options.onExpandClick?.()
  }

  return (
    <NodeViewWrapper>
      <RteImage
        src={props.node.attrs.src}
        alt={props.node.attrs.alt}
        float={props.node.attrs.float}
        width={props.node.attrs.width}
        onExpand={handleExpand}
      />
    </NodeViewWrapper>
  )
}
