import { DateTime } from 'luxon'

declare const __TESTING__: boolean

export type DateLike = string | Date | DateTime | number

export function safeParseDate(value: DateLike) {
  try {
    return parseDate(value)
  } catch (e) {
    return null
  }
}

/**
 * @throws Error if the value cannot be cast to a DateTime
 */
export const parseDate = (value: DateLike, zone = 'local'): DateTime => {
  if (typeof __TESTING__ !== 'undefined' && __TESTING__) {
    // Since we hardcode 'TZ' we use 'default' for testing
    if (zone === 'system' || zone === 'local') {
      zone = 'default'
    }
  }

  if (!value) {
    throw new Error(`[CastError] '${value}' is not castable to DateTime.`)
  }

  if (value instanceof DateTime) return value
  if (value instanceof Date) return DateTime.fromJSDate(value, { zone })

  if (typeof value === 'string') {
    if (value.length === 10 && value.includes('/')) {
      return DateTime.fromFormat(value, 'LL/dd/yyyy', { zone })
    } else if (value.length === 8 && value.includes('/')) {
      return DateTime.fromFormat(value, 'MM/dd/yy', { zone })
    }
    return DateTime.fromISO(value, { zone })
  }

  if (typeof value === 'number') return DateTime.fromMillis(value, { zone })

  if (typeof value === 'object') {
    const ctor = Object.getPrototypeOf(value)?.constructor?.name ?? 'unknown'
    throw new Error(
      `[CastError] The type [${ctor}] is not castable to DateTime.`,
      { cause: value }
    )
  }

  throw new Error(
    `[CastError] The type [${typeof value}] is not castable to DateTime.`,
    { cause: value }
  )
}
