import { type BaseToast, type CustomToast } from './types'

export interface ToastOptions {
  duration?: number
}

export type ToastType = (BaseToast | CustomToast) & {
  opts: Required<ToastOptions>
}
export type SubscriberToast = ToastType & {
  id: number
  dismissed: boolean
  removed: boolean
}
type ToastSubscriber = (toast: SubscriberToast) => void

class ToasterStateClass {
  subscribers: ToastSubscriber[]
  timeoutId: number | null
  toastCountId: number
  lastToast: SubscriberToast | null

  constructor() {
    this.subscribers = []
    this.timeoutId = null
    this.toastCountId = 0
    this.lastToast = null
  }

  subscribe(subscriber: ToastSubscriber) {
    this.subscribers.push(subscriber)

    if (
      this.lastToast != null &&
      !this.lastToast.dismissed &&
      !this.lastToast.removed
    ) {
      this.publish(this.lastToast)
    }

    return () => {
      const index = this.subscribers.indexOf(subscriber)
      this.subscribers.splice(index, 1)
    }
  }

  publish(data: SubscriberToast) {
    this.subscribers.forEach((subscriber) => subscriber(data))
  }

  private maybeClearTimeout() {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId)
      this.timeoutId = null
    }
  }

  addToast(data: ToastType) {
    this.lastToast = {
      ...data,
      id: this.toastCountId++,
      dismissed: false,
      removed: false,
    }

    this.publish(this.lastToast)
    this.maybeClearTimeout()
  }

  dismiss(data: SubscriberToast) {
    if (this.lastToast?.id !== data.id) return
    this.lastToast = { ...data, dismissed: true }

    this.publish({ ...data, dismissed: true })
    this.maybeClearTimeout()
    this.timeoutId = window.setTimeout(() => {
      this.publish({ ...data, removed: true })
      // Toast disappear with `animate-hide` which lasts 100ms
    }, 200)
  }
}

export const ToasterState = new ToasterStateClass()
