import { API, type LooseApiDefinition } from '@motion/rpc'

export type QueryReference = {
  query: LooseApiDefinition
  args?: unknown
}

export const prefetchQueries = {
  duringAuth: [
    {
      query: API.workspacesV2.getWorkspaces,
      args: {
        include: [
          'labels' as const,
          'projects' as const,
          'statuses' as const,
          'users' as const,
          'customFields' as const,
          'projectDefinitions' as const,
          'legacyProjectTemplates' as const,
        ],
      },
    },
    { query: API.views.getAll },
  ],
  postAuth: [{ query: API.usersV2.getMySettings }],
} satisfies { duringAuth: QueryReference[]; postAuth: QueryReference[] }
