import { Expand2Outline } from '@motion/icons'

import { type ComponentProps, type CSSProperties, useState } from 'react'

import { IconButton, ImageViewerModal, Tooltip } from '../../base'

type RteImageProps = ComponentProps<'img'> & {
  src: string
  alt: string
  float?: CSSProperties['float']
  width?: CSSProperties['width']
  className?: string
  onExpand?: () => void
}

export function RteImage({
  src,
  alt,
  float,
  width,
  onExpand,
  ...rest
}: RteImageProps) {
  const [zoomed, setIsZoomed] = useState(false)

  const handleExpand = () => {
    setIsZoomed(true)
    onExpand?.()
  }

  const onModalClose = () => {
    setIsZoomed(false)
  }

  return (
    <>
      <div
        className='custom-image'
        style={{
          float,
          width,
        }}
      >
        <img {...rest} src={src} alt={alt} className='w-full h-auto' />
        <div className='duration-150 absolute bottom-1 right-1'>
          <Tooltip asChild content='Expand image'>
            <IconButton
              onClick={handleExpand}
              icon={Expand2Outline}
              sentiment='neutral'
              variant='mutedBg'
              size='small'
            />
          </Tooltip>
        </div>
      </div>

      <ImageViewerModal
        src={src}
        alt={alt}
        onClose={onModalClose}
        visible={zoomed}
      />
    </>
  )
}
