import { createKey, defineApi } from '../../core'
import { type PMTaskType, type RecurringTask, TaskType } from '../misc-types'
import { type RouteTypes } from '../types'

export const queryKeys = {
  root: createKey('tasks'),
  list: () => createKey(queryKeys.root, 'list'),
  byId: (id: string) => createKey(queryKeys.root, 'detail', id),
  byWorkspaceId: (id: string) => createKey(queryKeys.root, 'workspace', id),
  byProjectId: (workspaceId: string, projectId: string) =>
    createKey(queryKeys.root, 'workspace', workspaceId, 'project', projectId),
}

type GetTasksById = RouteTypes<'TeamTasksController_get'>
export const getTaskById = defineApi<
  /* GetTasksById['request'] & */ { id: string },
  /* GetTasksById['response']['teamTask'] */ PMTaskType
>().using({
  key: (args) => queryKeys.byId(args.id),
  uri: (args) => `/team_tasks/${args.id}`,
  transform: (data: GetTasksById['response']) =>
    data.teamTask as unknown as PMTaskType,
  initialData(args, ctx) {
    const listCache = ctx.client.getQueryState(queryKeys.list())
    if (!listCache) return

    const list = listCache.data as (PMTaskType | RecurringTask)[] | undefined

    const found = list?.find((x) => x.id === args.id)
    if (!found) return

    return { value: found, updatedAt: listCache.dataUpdatedAt }
  },
})

type GetTasksByProjectId =
  RouteTypes<'V1ProjectsController_getAllTasksForProject'>
export const getTasksByProjectId = defineApi<
  GetTasksByProjectId['request'] & { workspaceId: string },
  // Overriding this until the endpoint is fixed
  PMTaskType[]
>().using({
  key: (args) => queryKeys.byProjectId(args.workspaceId, args.projectId),
  uri: (args) => `/projects/${args.projectId}/tasks`,
  transform: (data: GetTasksByProjectId['response']) => {
    // TODO The response of this query is weird https://usemotion.slack.com/archives/CKP2K3JBS/p1699445109956149
    // The tasks are in the index 1
    // @ts-expect-error - for the reasons above
    const projectTasks = data?.[1] as PMTaskType[] | undefined

    if (projectTasks) {
      return projectTasks
        .filter((task) => {
          return !task.archivedTime && task.type !== TaskType.CHUNK
        })
        .map((task) => {
          return {
            ...task,
            // @ts-expect-error `blockingTaskIds` exists on this project task endpoint
            blockingTasks: task.blockingTaskIds.map((blockedId) => ({
              blockedId,
            })),
            // @ts-expect-error `blockedTaskIds` exists on this project task endpoint
            blockedTasks: task.blockedTaskIds.map((blockedId) => ({
              blockedId,
            })),
          }
        })
    }

    return []
  },
})
