import { FolderOpenSolid, FolderSolid, type SvgIconProps } from '@motion/icons'
import { type COLOR } from '@motion/shared/common'

import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { ProjectPalette } from '../palette'

type FolderColoredIconProps = SvgIconProps & {
  open?: boolean
  colorOption?: string
}
export const FolderColoredIcon = forwardRef<
  SVGSVGElement,
  FolderColoredIconProps
>(function FolderIcon(
  { colorOption = 'gray', open = false, className, ...etc },
  ref
) {
  const Icon = open ? FolderOpenSolid : FolderSolid

  return (
    <ProjectPalette colorOption={colorOption as COLOR}>
      <Icon
        ref={ref}
        {...etc}
        className={twMerge('!text-palette-highlight-default', className)}
      />
    </ProjectPalette>
  )
})
