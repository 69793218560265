import { classed, type VariantProps } from '@motion/theme'

export type ProgressBarProps = {
  value: number
} & VariantProps<typeof ProgressBarContainer>

export const ProgressBar = ({ value, sentiment }: ProgressBarProps) => {
  return (
    <ProgressBarContainer sentiment={sentiment}>
      <ProgressBarInner
        sentiment={sentiment}
        style={{
          width: `${value}%`,
        }}
      />
    </ProgressBarContainer>
  )
}

const ProgressBarContainer = classed('div', {
  base: 'bg-opacity-10 h-[9px] rounded-sm',
  variants: {
    sentiment: {
      primary: 'bg-semantic-neutral-surface-overlay-bg-subtle',
      gradient: 'bg-purple-gradient',
    },
  },
  defaultVariants: {
    sentiment: 'primary',
  },
})

const ProgressBarInner = classed('div', {
  base: 'h-full bg-opacity-100 rounded-sm',
  variants: {
    sentiment: {
      primary: 'bg-button-neutral-outlined-border-focus',
      gradient: 'bg-purple-gradient',
    },
  },
  defaultVariants: {
    sentiment: 'primary',
  },
})
