import { Image, type ImageOptions } from '@tiptap/extension-image'
import { ReactNodeViewRenderer } from '@tiptap/react'

import { CustomImageView } from '../views'

type CustomImageOptions = ImageOptions & {
  onExpandClick: () => void
  floatOptions: Array<'left' | 'none' | 'right'>
  width: Array<'25%' | '50%' | '100%'>
}

// Code adapted from this blog: https://aboutweb.dev/blog/tiptap2-vue3-extending-image-functionality/
// CustomImage extends the Image Extension forTipTap editor to allow for custom image sizes and float options
export const CustomImageExtension = Image.extend<CustomImageOptions>({
  addAttributes() {
    return {
      // @ts-expect-error: Below expects an object, the function returns an object but the type is not defined
      ...Image.config.addAttributes(),
      float: {
        default: 'none',
        parseHTML: (element) => element.style.float.replace(/['"]+/g, ''),
        renderHTML: (attributes) => {
          const float = attributes.float?.float || attributes.float

          if (!float) {
            return {}
          }

          let style = `float: ${float}`

          if (float === 'left') {
            style += '; margin-right: 1em'
          } else if (float === 'right') {
            style += '; margin-left: 1em'
          }

          return {
            style: style,
          }
        },
      },
      width: {
        default: '100%',
        parseHTML: (element) => element.style.width.replace(/['"]+/g, ''),
        renderHTML: (attributes) => {
          // Initial load passes it as just attributes.width, editing passes it as attributes.width.width
          const width = attributes.width?.width || attributes.width

          if (!width) {
            return {}
          }

          return {
            style: `width: ${width}`,
          }
        },
      },
    }
  },

  addCommands() {
    return {
      setImage:
        (options) =>
        ({ tr, commands }) => {
          // @ts-expect-error: Below selection is an any type, which wont accept type or name in it
          if (tr.selection?.node?.type?.name === 'custom-image') {
            return commands.updateAttributes('custom-image', options)
          }
          return commands.insertContent({
            attrs: options,
            type: this.name,
          })
        },
    }
  },

  addOptions: () => {
    return {
      ...Image.options,
      floatOptions: ['left', 'none', 'right'],
      width: ['25%', '50%', '100%'],
      onExpandClick: () => {},
    }
  },

  name: 'custom-image',

  addNodeView() {
    return ReactNodeViewRenderer(CustomImageView)
  },
})
