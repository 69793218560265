import { createPlaceholderId } from '@motion/shared/identifiers'
import { omit, uniqueId } from '@motion/utils/core'
import { isWeekend, parseDate } from '@motion/utils/dates'
import {
  type ProjectSchema,
  type StageDefinitionSchema,
  type StageSchema,
} from '@motion/zod/client'

import { DateTime } from 'luxon'

import { type DeadlineInterval, type FlowTemplateStage } from './form-fields'

import {
  type AllAvailableCustomFieldSchema,
  mapCustomFieldToFieldArrayWithValue,
} from '../../custom-fields'
import { type StageArg } from '../form-fields'
import { reduceCustomFieldsValuesFieldArrayToRecord } from '../updates'

export function isStagePriorToActiveStage(
  project: ProjectSchema,
  stage: ProjectSchema['stages'][number]
) {
  const stageIndex = project.stages.findIndex(
    (s) => s.stageDefinitionId === stage.stageDefinitionId
  )
  const projectActiveStageIndex = project.stages.findIndex(
    (s) => s.stageDefinitionId === project.activeStageDefinitionId
  )
  return stageIndex < projectActiveStageIndex
}

export const isEnabledStage = (stage: StageArg): boolean => !stage.skipped

export function isStageActive(
  project: ProjectSchema | null | undefined,
  stageDefinitionId: string | null | undefined
) {
  return (
    project != null &&
    stageDefinitionId != null &&
    stageDefinitionId === project.activeStageDefinitionId
  )
}

export function isStageCompleted(stage: StageSchema): boolean {
  return stage.completedTime != null
}

export function isStageCanceled(stage: StageSchema): boolean {
  return stage.canceledTime != null
}

export function isValidStageDeadline(
  date: DateTime,
  projectStageId: StageSchema['id'],
  project: Pick<ProjectSchema, 'startDate' | 'dueDate'> & {
    stages: Pick<ProjectSchema['stages'][number], 'dueDate' | 'id'>[]
  }
) {
  const projectStartDate = project.startDate
    ? DateTime.fromISO(project.startDate)
    : null
  const projectDueDate = project.dueDate
    ? DateTime.fromISO(project.dueDate)
    : null

  // Date is before or after the project start/end
  if (projectStartDate != null && date < projectStartDate) return false
  if (projectDueDate != null && date > projectDueDate) return false

  // Otherwise we check based on the previous/next stage
  const stageIndex = project.stages.findIndex((s) => s.id === projectStageId)
  if (stageIndex === -1) return false

  const lowerBound =
    stageIndex === 0
      ? projectStartDate
      : DateTime.fromISO(project.stages[stageIndex - 1].dueDate)

  const upperBound =
    stageIndex === project.stages.length - 1
      ? projectDueDate
      : DateTime.fromISO(project.stages[stageIndex + 1].dueDate)

  if (lowerBound != null && date < lowerBound) return false
  if (upperBound != null && date > upperBound) return false

  return true
}

export function getProjectStage(
  project: ProjectSchema | null | undefined,
  stageDefinition: StageDefinitionSchema | null | undefined
): StageSchema | undefined {
  return project?.stages.find(
    (s) => s.stageDefinitionId === stageDefinition?.id
  )
}

export function getPreviousStage(
  project: ProjectSchema,
  stageDefinitionId: string
): StageSchema | undefined {
  const stageIndex = project?.stages.findIndex(
    (s) => s.stageDefinitionId === stageDefinitionId
  )

  if (stageIndex == null || stageIndex === 0) return undefined

  return project.stages[stageIndex - 1]
}

export function getStageStartDate(
  projectStartDate: DateTime,
  previousStageDueDate: string | undefined
): DateTime {
  if (previousStageDueDate == null) {
    // Include the project start date in the duration calculation
    // by "starting" the project in the calculation one business day before
    let date = projectStartDate.minus({ days: 1 })
    while (isWeekend(date)) {
      date = date.minus({ days: 1 })
    }
    return date
  }
  return parseDate(previousStageDueDate)
}

export type StageVariant = 'completed' | 'skipped' | 'active' | 'default'

export function getStageVariant(
  projectStage: StageSchema,
  isActive?: boolean
): StageVariant {
  if (isActive) {
    return 'active'
  }

  if (projectStage.completedTime != null) {
    return 'completed'
  }

  if (projectStage.skipped) {
    return 'skipped'
  }

  return 'default'
}

export type StageTense = 'past' | 'current' | 'future'

export const getStageTense = (
  project: ProjectSchema,
  projectStage: StageSchema
): StageTense => {
  if (isStageActive(project, projectStage.stageDefinitionId)) {
    return 'current'
  }

  const stageIndex = project.stages.findIndex(
    (s) => s.stageDefinitionId === projectStage.stageDefinitionId
  )
  const projectActiveStageIndex = project.stages.findIndex((s) =>
    isStageActive(project, s.stageDefinitionId)
  )

  return stageIndex < projectActiveStageIndex ? 'past' : 'future'
}

export const convertStageDefinitionToFormStage = (opts: {
  stage: StageDefinitionSchema
  workspaceCustomFields: AllAvailableCustomFieldSchema[]
  addPlaceholderTaskIds?: boolean
}): FlowTemplateStage => ({
  ...omit(opts.stage, ['deadlineIntervalDays']),
  deadlineInterval: convertDaysToDeadlineInterval(
    opts.stage.deadlineIntervalDays
  ),
  tasks: opts.stage.tasks.map((task) => ({
    ...omit(task, ['customFieldValues']),
    ...(opts.addPlaceholderTaskIds
      ? { id: createPlaceholderId(uniqueId('task')) }
      : {}),
    customFieldValuesFieldArray: opts.workspaceCustomFields.map((field) =>
      mapCustomFieldToFieldArrayWithValue(field, task.customFieldValues)
    ),
  })),
})

export function convertFormStagesToStageDefinition(
  stages: FlowTemplateStage[]
): StageDefinitionSchema[] {
  return stages.map((stage) => ({
    ...omit(stage, ['deadlineInterval']),
    deadlineIntervalDays: convertDeadlineIntervalToDays(stage.deadlineInterval),
    tasks: stage.tasks.map((task) => ({
      ...omit(task, ['customFieldValuesFieldArray']),
      customFieldValues: reduceCustomFieldsValuesFieldArrayToRecord(
        task.customFieldValuesFieldArray ?? [],
        { omitNull: true }
      ),
    })),
  }))
}

export function convertDaysToDeadlineInterval(
  deadlineIntervalDays: number
): DeadlineInterval {
  if (deadlineIntervalDays % 30 === 0) {
    return {
      unit: 'MONTHS',
      value: deadlineIntervalDays / 30,
    }
  }

  if (deadlineIntervalDays % 7 === 0) {
    return {
      unit: 'WEEKS',
      value: deadlineIntervalDays / 7,
    }
  }

  return {
    unit: 'DAYS',
    value: deadlineIntervalDays,
  }
}

export function convertDeadlineIntervalToDays(
  deadlineInterval: DeadlineInterval
): number {
  switch (deadlineInterval.unit) {
    case 'DAYS':
      return deadlineInterval.value
    case 'WEEKS':
      return deadlineInterval.value * 7
    case 'MONTHS':
      return deadlineInterval.value * 30
  }
}
