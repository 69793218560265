import {
  ArrowLeftOutline,
  CheckSolid,
  DesktopComputerSolid,
  ShieldCheckSolid,
  StripeLogo,
} from '@motion/icons'
import { Button } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import {
  type BillingPrices,
  generateBillingFormula,
  INDIVIDUAL_PRICES,
} from '@motion/ui-logic/billing'

import { twMerge } from 'tailwind-merge'

import { BillingPlanSelector } from '../billing-plan-selector'
import { useShouldShowSalesTaxMessage } from '../hooks'

type BillingPaymentInformationProps = {
  userEmail: string
  onChangeEmail: () => void
  hideChangeEmail: boolean
}

export const BillingPaymentInformation = ({
  userEmail,
  onChangeEmail,
  hideChangeEmail,
}: BillingPaymentInformationProps) => {
  return (
    <>
      <p className='mt-2 mb-2 text-semantic-neutral-text-default text-xl font-semibold'>
        Payment information
      </p>
      {!hideChangeEmail && (
        <div className='bg-semantic-neutral-bg-default rounded-lg flex flex-row justify-between items-center px-4 py-2 mb-3'>
          <p className='text-base text-semantic-neutral-text-default flex-grow truncate'>
            {userEmail}
          </p>
          <div className='flex-shrink-0'>
            <Button sentiment='primary' variant='muted' onClick={onChangeEmail}>
              Change
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

type BillingHeaderProps = {
  trialLength?: number
  isMobile: boolean
}

export const BillingHeader = ({
  trialLength,
  isMobile,
}: BillingHeaderProps) => {
  return (
    <>
      <p className='text-semantic-neutral-text-default text-2xl font-semibold mb-4'>
        Start your {trialLength}-day free trial{isMobile ? '' : ' of Motion'}
      </p>
      <p className='text-semantic-neutral-text-default text-sm font-normal mb-6'>
        Start saving 30.3 days every single year.
      </p>
    </>
  )
}

type BillingFooterProps = {
  isAnnual: boolean
  price: number
  isTeam: boolean
  bucket?: number
}

export const BillingFooter = ({
  isAnnual,
  isTeam,
  bucket,
  price,
}: BillingFooterProps) => {
  const shouldShowSalesTaxMessage = useShouldShowSalesTaxMessage()

  return (
    <p className='max-w-screen-sm mt-4 mb-0 text-semantic-neutral-text-disabled self-center text-xs font-medium text-center'>
      {templateStr(
        'Cancel anytime on your account page or by emailing/chatting support. Your subscription is on {{basis}} basis of {{formula}}{{applicableTax}}. Your subscription will automatically renew unless cancelled.',
        {
          basis: isAnnual ? 'an annual' : 'a monthly',
          formula: generateBillingFormula({
            isTeam,
            isAnnual,
            seats: isTeam ? bucket : undefined,
            monthlyPrice: price,
          }),
          applicableTax: shouldShowSalesTaxMessage
            ? ', plus applicable taxes'
            : '',
        }
      )}
    </p>
  )
}

type MobileSignupWarningProps = {
  isMobile: boolean
}

export const MobileSignupWarning = ({ isMobile }: MobileSignupWarningProps) => {
  return (
    isMobile && (
      <div className='mt-4 bg-semantic-neutral-bg-active-default rounded p-2'>
        <DesktopComputerSolid
          width={18}
          height={18}
          className='text-semantic-neutral-icon-default'
        />
        <div className='mt-2'>
          <h3 className='text-xs font-medium'>
            Motion is best when used on a computer
          </h3>

          <p className='mt-2 text-xs text-semantic-neutral-text-subtle'>
            If you&apos;re planning to use Motion only on mobile, it may not be
            for you.
          </p>
        </div>
      </div>
    )
  )
}

type BillingCardHoldWarningProps = {
  visible: boolean
}

export const BillingCardHoldWarning = ({
  visible,
}: BillingCardHoldWarningProps) => {
  return (
    visible && (
      <p className='mt-2 mb-0 text-semantic-neutral-text-default text-sm'>
        To verify that your card is valid, we will put a temporary $1 hold on
        your card. The hold will be returned automatically after 7 days.
      </p>
    )
  )
}

export const SafeCheckoutGuarantee = () => {
  return (
    <div className='mt-4 flex flex-row items-center justify-start'>
      <ShieldCheckSolid className='text-semantic-success-bg-strong-default h-5 w-5' />
      <p className='text-semantic-neutral-text-default mb-0 ml-2.5 text-sm font-medium'>
        Guaranteed safe checkout
      </p>
      <div className='bg-semantic-neutral-bg-strong-active ml-4 rounded py-1 px-1.5'>
        <StripeLogo className='text-white' width='70' height='14' />
      </div>
    </div>
  )
}

type BillingPaymentPageProps = {
  paymentElement: React.ReactNode
  trialLength?: number
  isAnnual: boolean
  setIsAnnual: (annual: boolean) => void
  isMobile: boolean
  userEmail: string
  onChangeEmail: () => void
  hasSelectedCard: boolean
  error: string | null
  isPaymentFilledOut: boolean
  isSubmitting: boolean
  handleSubmit: () => void
  onBack?: () => void
  hideChangeEmail?: boolean
  teamPrices?: BillingPrices
} & (
  | {
      isTeam: false
      bucket?: undefined
      setBucket?: undefined
      teamPrices?: undefined
    }
  | {
      isTeam: true
      teamPrices: BillingPrices
      bucket: number
      setBucket: (bucket: number) => void
    }
)

type SubmitButtonProps = {
  disabled: boolean
  loading: boolean
  onClick: () => void
  trialLength?: number
}

export const SubmitButton = ({
  disabled,
  loading,
  onClick,
  trialLength,
}: SubmitButtonProps) => {
  return (
    <Button
      disabled={disabled}
      loading={loading}
      onClick={onClick}
      id='submit-subscribe-button'
    >
      {trialLength ? `Start ${trialLength} day trial` : 'Start subscription'}
    </Button>
  )
}

export const BillingPaymentPage = ({
  paymentElement,
  trialLength,
  isAnnual,
  setIsAnnual,
  isMobile,
  userEmail,
  onChangeEmail,
  hasSelectedCard,
  error,
  isPaymentFilledOut,
  isSubmitting,
  handleSubmit,
  onBack,
  hideChangeEmail = false,
  ...teamProps
}: BillingPaymentPageProps) => {
  const isTeam = teamProps.isTeam
  const prices = isTeam ? teamProps.teamPrices : INDIVIDUAL_PRICES

  return (
    <div
      className={twMerge(
        'max-w-screen-md grow border-semantic-neutral-border-default bg-semantic-neutral-bg-subtle flex flex-col rounded-t-lg p-4 lg:rounded-r-none lg:rounded-l-lg lg:px-12',
        onBack ? '' : 'lg:py-8'
      )}
    >
      {onBack && (
        <div className='w-full flex mb-3'>
          <Button
            sentiment='neutral'
            variant='muted'
            size='small'
            onClick={onBack}
          >
            <ArrowLeftOutline className='w-4 h-4 text-semantic-neutral-icon-default' />
            Select plan
          </Button>
        </div>
      )}
      {!!trialLength && (
        <BillingHeader trialLength={trialLength} isMobile={isMobile} />
      )}
      <BillingPlanSelector
        isAnnual={isAnnual}
        setIsAnnual={setIsAnnual}
        savingsPercent={prices.annualSavingsPercentInteger}
        text={isTeam ? 'Team Plan' : 'Individual Plan'}
        subText={isAnnual ? 'Billed yearly' : 'Billed monthly'}
        price={isAnnual ? prices.annualPricePerMonth : prices.monthlyPrice}
        trialLength={trialLength}
        {...teamProps}
      />
      <BillingPaymentInformation
        userEmail={userEmail}
        onChangeEmail={onChangeEmail}
        hideChangeEmail={hideChangeEmail}
      />
      {paymentElement}
      <BillingCardHoldWarning visible={!hasSelectedCard} />
      <SafeCheckoutGuarantee />
      {error && (
        <p className='text-semantic-error-text-default mb-4'>{error}</p>
      )}
      <div className='mt-6 self-center'>
        <SubmitButton
          disabled={!isPaymentFilledOut}
          loading={isSubmitting}
          onClick={handleSubmit}
          trialLength={trialLength}
        />
      </div>
      <BillingFooter
        isAnnual={isAnnual}
        price={isAnnual ? prices.annualPricePerMonth : prices.monthlyPrice}
        bucket={isTeam ? teamProps.bucket : undefined}
        isTeam={isTeam}
      />
      <MobileSignupWarning isMobile={isMobile} />
    </div>
  )
}

const motionFeatures = [
  'Task & Project management',
  'Intelligent schedule builder',
  'Calendar syncing & management',
  'Meeting booking pages',
  '1-click email assistant',
  'Mobile, Web & Desktop apps',
  'API and Integrations',
  'Free upgrades and new features',
  'Customer support',
]

type MotionFeatureProps = {
  text: string
}

const MotionFeature = ({ text }: MotionFeatureProps) => {
  return (
    <div className='mb-3 flex flex-row items-center justify-start gap-x-2'>
      <CheckSolid className='h-5 w-5 text-semantic-primary-bg-strong-default bg-semantic-primary-bg-active-default rounded-full p-0.5' />
      <p className='text-semantic-neutral-text-default mb-0 text-base font-normal'>
        {text}
      </p>
    </div>
  )
}

type BillingFeaturesProps = {
  trialLength?: number
}

export const BillingFeatures = ({ trialLength }: BillingFeaturesProps) => {
  return (
    <div className='max-w-screen-md flex flex-col rounded-b-lg bg-semantic-neutral-bg-default overflow-hidden lg:rounded-l-none lg:rounded-r-lg lg:w-[390px] lg:border-l'>
      {!!trialLength && <BillingTimeline trialLength={trialLength} />}
      <div className='p-6'>
        <p className='text-semantic-neutral-text-default mb-5 text-base font-semibold'>
          What&apos;s included with Motion:
        </p>
        {motionFeatures.map((feature) => (
          <MotionFeature key={feature} text={feature} />
        ))}
      </div>
    </div>
  )
}

type BillingTimelineProps = {
  trialLength: number
}

export const BillingTimeline = ({ trialLength }: BillingTimelineProps) => {
  return (
    <div className='flex bg-semantic-blue-bg-default py-6 px-4'>
      <div className='flex flex-col items-center mr-3'>
        <CheckSolid className='h-5 w-5 bg-semantic-primary-bg-strong-default text-semantic-neutral-bg-default rounded-full p-0.5' />
        <div className='h-12 w-0.5 bg-semantic-primary-border-active'></div>
        <div className='h-5 w-5 rounded-full border-2 border-semantic-primary-border-active bg-semantic-neutral-bg-default'></div>
        <div className='h-[72px] w-0.5 bg-semantic-primary-border-active'></div>
        <div className='h-5 w-5 rounded-full border-2 border-semantic-primary-border-active bg-semantic-neutral-bg-default'></div>
      </div>
      <div>
        <div className='h-[68px]'>
          <p className='font-bold mb-1'>Today:</p>
          <p>Your trial begins free for {trialLength} days</p>
        </div>
        <div className='h-[92px]'>
          <p className='font-bold mb-1'>Day {trialLength - 2}:</p>
          <p>
            We will send you an email reminder that your trial is about to
            expire
          </p>
        </div>
        <div>
          <p className='font-bold mb-1'>Day {trialLength}:</p>
          <p>
            Your subscription starts when your trial ends. You can cancel your
            subscription anytime on your account page, or by emailing or
            chatting with support
          </p>
        </div>
      </div>
    </div>
  )
}
