import { parseDate } from '@motion/utils/dates'

import { type SetupProjectFormFields } from '../../form-fields'

export function getSetupProjectStartDateChangedFields(
  fields: SetupProjectFormFields,
  _prevFields: SetupProjectFormFields
): Partial<SetupProjectFormFields> {
  const updates: Partial<SetupProjectFormFields> = {}

  const deadlineDate = fields.dueDate
  const startDate = fields.startDate

  if (startDate && deadlineDate) {
    const start = parseDate(startDate)
    const deadline = parseDate(deadlineDate)

    if (start > deadline) {
      updates.dueDate = start.endOf('day').plus({ days: 1 }).toISO()
    }
  }

  return updates
}
