import { type ReactNode } from 'react'

export type UserCardProps = {
  /**
   * Usually the avatar of the user
   */
  icon: ReactNode
  name: ReactNode
  email: ReactNode
  tag?: ReactNode
}

export function UserCard({ icon, name, email, tag }: UserCardProps) {
  return (
    <div className='flex items-start gap-2 overflow-hidden'>
      <div className='mt-[0.5px]'>{icon}</div>

      <div className='overflow-hidden'>
        <div className='text-sm flex items-center gap-1 leading-4'>
          <p className='truncate'>{name}</p>
          {tag != null && (
            <span className='rounded px-0.5 text-xs text-semantic-neutral-text-subtle bg-semantic-neutral-surface-bg-subtle'>
              {tag}
            </span>
          )}
        </div>

        <p className='truncate text-semantic-neutral-text-subtle leading-4 text-xs'>
          {email}
        </p>
      </div>
    </div>
  )
}
