import { CheckSolid } from '@motion/icons'
import { Button, FormModal, Tag } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { isEnterpriseEmail, templateStr } from '@motion/ui-logic'
import {
  enterpriseBullets,
  enterpriseBulletsV2,
  enterpriseElevatorPitch,
  enterpriseElevatorPitchV2,
  INDIVIDUAL_ANNUAL_PRICE_AMORTIZED_MONTHLY,
  individualBullets,
  individualBulletsV2,
  individualElevatorPitch,
  individualElevatorPitchV2,
  makeTeamBullets,
  PlanNamesV1,
  PlanNamesV2,
  teamElevatorPitch,
  teamElevatorPitchV2,
} from '@motion/ui-logic/billing'

import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { useEnterpriseLink, useShouldShowSalesTaxMessage } from './hooks'

type BillingPlanCardProps = {
  subtitle: string
  sectionHeader?: string
  items: string[]
  onClick: () => void
  isTeam: boolean
  price?: number
  savingsDescription?: string
  buttonText?: string
  planType: 'Individual' | 'Team' | 'Enterprise'
  border?: boolean
  isSecondary?: boolean
  hidePrice?: boolean
  showV2: boolean
}

const BillingPlanCard = ({
  subtitle,
  sectionHeader,
  items,
  onClick,
  isTeam,
  price,
  savingsDescription,
  buttonText,
  planType,
  border,
  isSecondary,
  hidePrice,
  showV2,
}: BillingPlanCardProps) => {
  const shouldShowSalesTaxMessage = useShouldShowSalesTaxMessage()

  return (
    <div
      className={twMerge(
        'flex flex-col flex-1 max-w-[500px] min-w-[300px] justify-top rounded bg-semantic-neutral-bg-default p-6 drop-shadow-md',
        border && 'border border-semantic-neutral-border-default'
      )}
    >
      <div className='pb-8'>
        <div className='flex flex-row w-full justify-between mb-2'>
          <p className='text-semantic-neutral-text-default text-lg font-bold'>
            {showV2 ? PlanNamesV2[planType] : PlanNamesV1[planType]}
          </p>
          {isTeam && savingsDescription && (
            <Tag size='small' color='green' variant='subtle'>
              <strong>{savingsDescription}</strong>
            </Tag>
          )}
        </div>
        <p
          className={twMerge(
            'text-semantic-neutral-text-subtle lg:mb-4 text-sm',
            showV2 ? '' : 'font-medium'
          )}
        >
          {subtitle}
        </p>
        <p className={twMerge(showV2 ? 'pb-2' : 'pb-6')}>
          {hidePrice ? null : price ? (
            <>
              <span className='text-semantic-neutral-text-default text-xl font-bold'>
                ${price}
              </span>
              <span className='text-semantic-neutral-text-subtle text-sm font-normal'>
                {templateStr(' per month{{userStr}}{{applicableTax}}', {
                  userStr: isTeam ? ' per user' : '',
                  applicableTax: shouldShowSalesTaxMessage
                    ? ' (plus applicable taxes)'
                    : '',
                })}
              </span>
            </>
          ) : (
            <>
              <span className='text-semantic-neutral-text-default text-xl font-bold pr-1'>
                Contact us
              </span>
              <span className='text-semantic-neutral-text-subtle text-sm font-normal'>
                (20 seat minimum)
              </span>
            </>
          )}
        </p>
        <Button
          fullWidth
          onClick={onClick}
          sentiment={isSecondary ? 'neutral' : 'primary'}
          data-testid={`choose-plan-${planType}`}
        >
          {buttonText ? buttonText : `Continue as ${planType}`}
        </Button>
      </div>
      {sectionHeader && (
        <p className='text-semantic-neutral-text-subtle mb-2 text-sm font-normal'>
          {sectionHeader}
        </p>
      )}
      <div className='flex mb-6 flex-col gap-2'>
        {items.map((item) => (
          <div key={item} className='flex gap-2 place-content-start'>
            <CheckSolid className='text-semantic-primary-icon-default h-4 w-4 shrink-0 mt-[2px]' />
            <p className='text-semantic-neutral-text-subtle mb-0 text-sm font-normal'>
              {item}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

type IndividualBillingPlanCardProps = Pick<
  BillingPlanCardProps,
  'onClick' | 'buttonText' | 'hidePrice' | 'isSecondary' | 'showV2'
>

export const IndividualBillingPlanCard = ({
  onClick,
  buttonText,
  hidePrice,
  isSecondary,
  showV2,
}: IndividualBillingPlanCardProps) => {
  return (
    <BillingPlanCard
      isTeam={false}
      subtitle={showV2 ? individualElevatorPitchV2 : individualElevatorPitch}
      items={showV2 ? individualBulletsV2 : individualBullets}
      onClick={onClick}
      price={INDIVIDUAL_ANNUAL_PRICE_AMORTIZED_MONTHLY}
      buttonText={showV2 ? 'Try Motion for free' : buttonText}
      planType='Individual'
      hidePrice={hidePrice}
      isSecondary={isSecondary}
      showV2={showV2}
    />
  )
}

type TeamBillingPlanCardProps = Pick<
  BillingPlanCardProps,
  'onClick' | 'buttonText' | 'hidePrice' | 'border' | 'price' | 'showV2'
> & {
  savings?: number
}

export const TeamBillingPlanCard = ({
  onClick,
  buttonText,
  hidePrice,
  savings,
  price,
  border,
  showV2,
}: TeamBillingPlanCardProps) => {
  return (
    <BillingPlanCard
      isTeam
      subtitle={showV2 ? teamElevatorPitchV2 : teamElevatorPitch}
      sectionHeader='Everything in Individual, plus:'
      items={makeTeamBullets(showV2, savings)}
      onClick={onClick}
      price={price}
      savingsDescription={
        savings ? `${savings}% cheaper than Individual` : undefined
      }
      planType='Team'
      border={border}
      buttonText={showV2 ? 'Try Motion for free' : buttonText}
      hidePrice={hidePrice}
      showV2={showV2}
    />
  )
}

type EnterpriseBillingPlanCardProps = Pick<
  BillingPlanCardProps,
  'hidePrice' | 'border' | 'showV2'
> & { location: string; email?: string }

export const EnterpriseBillingPlanCard = ({
  hidePrice,
  border,
  location,
  email,
  showV2,
}: EnterpriseBillingPlanCardProps) => {
  const openEnterpriseLink = useEnterpriseLink()
  const [showModal, setShowModal] = useState(false)

  const onAction = (newEmail?: string) => {
    openEnterpriseLink(location, newEmail ?? email)
  }

  return (
    <>
      <BillingPlanCard
        isTeam
        subtitle={showV2 ? enterpriseElevatorPitchV2 : enterpriseElevatorPitch}
        sectionHeader={templateStr('Everything in {{plan}}, plus:', {
          plan: showV2 ? PlanNamesV2['Team'] : PlanNamesV1['Team'],
        })}
        items={showV2 ? enterpriseBulletsV2 : enterpriseBullets}
        onClick={() => {
          if (email && isEnterpriseEmail(email)) {
            onAction()
          } else {
            setShowModal(true)
          }
        }}
        savingsDescription='Dedicated expert ($10k value) for free'
        buttonText={
          hidePrice && !showV2 ? 'Book Demo (25+ seats)' : 'Book Demo'
        }
        planType='Enterprise'
        border={border}
        hidePrice={hidePrice}
        showV2={showV2}
      />
      {showModal && (
        <EnterpriseEmailModal
          onClose={() => setShowModal(false)}
          onAction={onAction}
        />
      )}
    </>
  )
}

type EnterpriseModalProps = {
  onClose: () => void
  onAction: (newEmail?: string) => void
}

const EnterpriseEmailModal = ({ onClose, onAction }: EnterpriseModalProps) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [workEmail, setWorkEmail] = useState('')

  return (
    <FormModal
      title='Please enter your work email'
      visible
      onClose={onClose}
      submitAction={{
        text: 'Submit',
        onAction: () => {
          if (!isEnterpriseEmail(workEmail)) {
            setErrorMessage(
              templateStr(
                "{{email}}, is not a valid work email, please enter an email for your company's domain",
                { email: workEmail }
              )
            )
          } else {
            onAction(workEmail)
            onClose()
          }
        },
      }}
    >
      <div className='w-[400px] flex flex-col gap-4'>
        <TextField
          placeholder='Enter your work email'
          value={workEmail}
          onChange={setWorkEmail}
        />
        {!!errorMessage && (
          <p className='text-semantic-error-text-default text-xs'>
            {errorMessage}
          </p>
        )}
      </div>
    </FormModal>
  )
}
