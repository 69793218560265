import {
  ToasterState,
  type ToastOptions,
  type ToastType,
} from './toaster-state'
import type { BaseToast, CustomToast } from './types'

const defaultOptions: Required<ToastOptions> = {
  duration: 5000,
}

export function showToast(
  type: 'custom',
  renderFn: CustomToast['renderFn'],
  options?: ToastOptions
): void
export function showToast(
  type: BaseToast['type'],
  message: BaseToast['message'],
  options?: ToastOptions
): void
export function showToast<T extends ToastType['type']>(
  type: T,
  messageOrRender: BaseToast['message'] | CustomToast['renderFn'],
  options: ToastOptions = {}
): void {
  const opts = { ...defaultOptions, ...options }

  if (type === 'custom') {
    ToasterState.addToast({
      type,
      renderFn: messageOrRender as CustomToast['renderFn'],
      opts,
    })
  } else {
    ToasterState.addToast({
      type,
      message: messageOrRender as BaseToast['message'],
      opts,
    })
  }
}
