import { type PMTaskType, type RecurringTask } from '@motion/rpc/types'
import { shiftDateToZone } from '@motion/utils/dates'
import {
  type ProjectSchema,
  type RecurringTaskSchema,
  type TaskSchema,
} from '@motion/zod/client'

import { DateTime } from 'luxon'

import { isRecurringTaskParent } from '../../task-utils'
import { isReminderTask } from '../helpers'

export function getTaskInitialStartDate(): string
export function getTaskInitialStartDate(
  task?: PMTaskType | RecurringTask | TaskSchema | RecurringTaskSchema | null,
  knownDueDate?: string | null
): string | null
export function getTaskInitialStartDate(
  task?: PMTaskType | RecurringTask | TaskSchema | RecurringTaskSchema | null,
  knownDueDate?: string | null
): string | null {
  const today = DateTime.now().startOf('day')

  if (!task) {
    const dueDate = knownDueDate != null ? DateTime.fromISO(knownDueDate) : null

    if (dueDate != null && dueDate < today) {
      return dueDate.minus({ days: 1 }).startOf('day').toISODate()
    }

    return today.toISODate()
  }

  const isRecurring = isRecurringTaskParent(task)

  if (isRecurring) {
    return task.startingOn
      ? shiftDateToZone(task.startingOn).toISODate()
      : today.toISODate()
  }

  if (
    task.completedTime &&
    task.startDate &&
    task.completedTime < task.startDate
  ) {
    return shiftDateToZone(task.completedTime).toISODate()
  }

  if (task.startDate) {
    return shiftDateToZone(task.startDate).toISODate()
  }

  if (task.isAutoScheduled) {
    return today.toISODate()
  }

  return null
}

export function getTaskDefaultDueDate(
  startAt: DateTime = DateTime.now(),
  project?: ProjectSchema | null
): string {
  const startingDate = startAt.startOf('day')
  const dayAfterStart = startingDate.plus({ days: 1 }).endOf('day')

  if (project && project.dueDate) {
    const projectDeadline = DateTime.fromISO(project.dueDate).endOf('day')
    if (projectDeadline < startingDate) {
      return dayAfterStart.toISO()
    }
    return projectDeadline.toISO()
  }

  return dayAfterStart.toISO()
}

export const getScheduledDate = (
  task?: TaskSchema | RecurringTaskSchema
): DateTime | null => {
  if (!task) return null

  try {
    if (
      isReminderTask(task) &&
      task.type !== 'RECURRING_TASK' &&
      task.dueDate != null
    ) {
      return DateTime.fromISO(task.dueDate)
    }

    if (
      'estimatedCompletionTime' in task &&
      task.estimatedCompletionTime != null
    ) {
      return DateTime.fromISO(task.estimatedCompletionTime)
    }
  } catch (err) {}

  return null
}
