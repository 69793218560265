import {
  type NormalOrRecurringTask,
  type PMTaskType,
  type RecurringTask,
} from '@motion/rpc/types'
import {
  type NormalTaskSchema,
  type PriorityLevel,
  type RecurringInstanceSchema,
  type TaskSchema,
} from '@motion/rpc-types'
import { isCompletedStatus } from '@motion/shared/common'
import { byProperty, cascade, Compare, ordered } from '@motion/utils/array'
import { cloneDeep } from '@motion/utils/core'
import { parseDate } from '@motion/utils/dates'

import { DateTime } from 'luxon'

import { isChunkable } from './task'

export const pmComputeDeadlineDiff = (
  dueDate: string,
  scheduledStart: string
) => {
  const dueDateLuxon = DateTime.fromISO(dueDate)
  const scheduledStartLuxon = DateTime.fromISO(scheduledStart)
  const minuteDiff = dueDateLuxon.diff(scheduledStartLuxon, 'minutes').minutes
  return minuteDiff
}

const pmTaskDeadlineCompareFn = (a: PMTaskType, b: PMTaskType) => {
  const aDeadlineDiff = a.dueDate
    ? Math.max(
        pmComputeDeadlineDiff(
          a.dueDate,
          a.scheduledStart ?? DateTime.now().toISO()
        ),
        0
      )
    : -1
  const bDeadlineDiff = b.dueDate
    ? Math.max(
        pmComputeDeadlineDiff(
          b.dueDate,
          b.scheduledStart ?? DateTime.now().toISO()
        ),
        0
      )
    : -1

  if (aDeadlineDiff === bDeadlineDiff) {
    return (a.scheduledStart ?? -1) < (b.scheduledStart ?? -1) ? -1 : 1
  }
  return aDeadlineDiff - bDeadlineDiff
}

// Sort the chunks by the scheduled end of each chunk, with the earliest task being first.
export const sortTaskChunksByScheduledEnd = (
  chunkA: PMTaskType | Pick<TaskSchema, 'scheduledEnd'>,
  chunkB: PMTaskType | Pick<TaskSchema, 'scheduledEnd'>
) => {
  if (chunkA.scheduledEnd == null) {
    return 1 // chunkA is after chunkB
  } else if (chunkB.scheduledEnd == null) {
    return -1 // chunkA is before chunkB
  }

  if (chunkA.scheduledEnd < chunkB.scheduledEnd) {
    return -1 // chunkA is before chunkB
  } else if (chunkA.scheduledEnd > chunkB.scheduledEnd) {
    return 1 // chunkA is after chunkB
  }

  return 0
}

export const computePMDeadlineSplit = (scheduledTasks: PMTaskType[]) => {
  const afterDeadlineTasks: PMTaskType[] = []
  const todayTasks: PMTaskType[] = []
  const tomorrowTasks: PMTaskType[] = []
  const futureTasks: PMTaskType[] = []

  const now = DateTime.now()
  const today = now.startOf('day')
  const todayStr = today.toISODate()
  const tomorrowStr = today.plus({ day: 1 }).toISODate()
  scheduledTasks.forEach((scheduledTask) => {
    const task = cloneDeep(scheduledTask)

    if (task.chunks?.length) {
      const incompleteChunks = cloneDeep(task.chunks)
        .filter((chunk) => !(chunk.status && isCompletedStatus(chunk.status)))
        .sort(sortTaskChunksByScheduledEnd)

      if (incompleteChunks.length) {
        // Ensure the last chunk is the one that is latest in due date
        const chunk = incompleteChunks[incompleteChunks.length - 1]
        task.scheduledStart = chunk.scheduledStart
        task.scheduledEnd = chunk.scheduledEnd
        task.providerId = chunk.providerId
        task.sortKey = chunk.scheduledStart ?? DateTime.now().toISO()
      }
    }
    const isAsap = task.priorityLevel === 'ASAP'
    if (task.dueDate) {
      let startDateFormatted: string | null = null

      if (task.needsReschedule) {
        // Resolve start date from startDate instead of scheduledStart, since
        // tasks that have been scheduled to a different date still have their
        // old scheduledStart value.
        startDateFormatted = task.startDate
          ? DateTime.fromISO(task.startDate).toISODate()
          : null
      } else {
        startDateFormatted = task.scheduledStart
          ? DateTime.fromISO(task.scheduledStart).toISODate()
          : null
      }

      if (isTaskPastDue(task) && !task.ignoreWarnOnPastDue) {
        afterDeadlineTasks.push(task)
      }

      if (isAsap || startDateFormatted === todayStr) {
        todayTasks.push(task)
      } else if (startDateFormatted === tomorrowStr) {
        tomorrowTasks.push(task)
      } else if ((startDateFormatted ?? '') > todayStr) {
        if (!task.parentRecurringTaskId) {
          futureTasks.push(task)
        }
      }
    }
  })

  afterDeadlineTasks.sort(pmTaskDeadlineCompareFn)
  tomorrowTasks.sort((a, b) =>
    (a.scheduledStart ?? 0) < (b.scheduledStart ?? 0) ? -1 : 1
  )

  todayTasks.sort(
    cascade(
      byProperty(
        'priorityLevel',
        ordered<PriorityLevel>(['LOW', 'MEDIUM', 'HIGH', 'ASAP'])
      ),
      byProperty('scheduledStart', Compare.caseInsensitive)
    )
  )

  futureTasks.sort((a, b) => {
    // Ensure "to be scheduled" tasks appear at the top
    const needsRescheduleSort =
      Number(b.needsReschedule ?? false) - Number(a.needsReschedule ?? false)
    if (needsRescheduleSort !== 0) {
      return needsRescheduleSort
    }
    return (a.scheduledStart ?? 0) < (b.scheduledStart ?? 0) ? -1 : 1
  })

  return {
    afterDeadlineTasks,
    futureTasks: futureTasks.slice(0, 30),
    todayTasks,
    tomorrowTasks,
  }
}

const sortRank = (a: PMTaskType, b: PMTaskType) => {
  if (a.rank === b.rank) return 0

  if (!a.rank) return 1
  if (!b.rank) return -1

  if (a.rank < b.rank) {
    return -1
  }
  if (a.rank > b.rank) {
    return 1
  }

  return 0
}

const taskContainsUserId = (
  task: PMTaskType,
  userId: string | null
): boolean => {
  if (userId) {
    return task.assigneeUserId === userId
  }
  return false
}

/**
 * @deprecated to be removed when using past due v2 endpoint
 */
export const computePMScheduledTasks = (
  tasks: PMTaskType[],
  currentUserId: string | null
) => {
  // Compute tasks into the following blocks:
  // ScheduledAfterDue
  // Today
  // Tomorrow
  // Rest of week
  // Next week
  // Make sure to filter tasks by priority for when tasks are updated on the
  // backend.
  const tasksCopy = tasks.filter((t) => t.isAutoScheduled)

  const isAsap = (task: PMTaskType) => task.priorityLevel === 'ASAP'

  const deadlineSplit = computePMDeadlineSplit(tasksCopy)
  const scheduledAfterDueTasks = deadlineSplit.afterDeadlineTasks.filter(
    (task) => taskContainsUserId(task, currentUserId)
  )

  const todayTasks = [
    ...tasksCopy.filter(isAsap),
    ...deadlineSplit.todayTasks.filter((task) => !isAsap(task)),
  ]
    .filter((task) => taskContainsUserId(task, currentUserId))
    .sort(sortRank)
  const tomorrowTasks = [...deadlineSplit.tomorrowTasks]
    .filter((task) => taskContainsUserId(task, currentUserId))
    .sort(sortRank)
  let restOfWeekTasks: PMTaskType[] = []
  let nextWeekTasks: PMTaskType[] = []

  const today = DateTime.now().startOf('day')
  const weekEndDate = DateTime.now().endOf('week')

  // Only push tasks if today is not a friday-sunday
  if (today.weekday < 5) {
    restOfWeekTasks = deadlineSplit.futureTasks.filter((task) => {
      const start = task.needsReschedule
        ? task.startDate
          ? DateTime.fromISO(task.startDate)
          : DateTime.now()
        : task.scheduledStart
          ? DateTime.fromISO(task.scheduledStart)
          : DateTime.now()

      return (
        taskContainsUserId(task, currentUserId) &&
        (!task.priorityLevel || !isAsap(task)) &&
        start < weekEndDate
      )
    })
  }

  const nextWeekEndDate = today.plus({ week: 1 }).endOf('week')
  nextWeekTasks = deadlineSplit.futureTasks.filter((task) => {
    const start = task.needsReschedule
      ? task.startDate
        ? DateTime.fromISO(task.startDate)
        : DateTime.now()
      : task.scheduledStart
        ? DateTime.fromISO(task.scheduledStart)
        : DateTime.now()

    return (
      taskContainsUserId(task, currentUserId) &&
      !isAsap(task) &&
      start > weekEndDate &&
      start < nextWeekEndDate
    )
  })

  return {
    nextWeekTasks,
    restOfWeekTasks,
    scheduledAfterDueTasks,
    todayTasks,
    tomorrowTasks,
  }
}

export const getLastScheduledEndFromPmTask = (
  task: PMTaskType
): string | null => {
  if (isChunkable(task) && task.chunks?.length) {
    // Get the first incomplete and scheduled chunk, ignoring completed chunks
    const incompleteChunks = cloneDeep(task.chunks)
      .filter(
        (chunk) =>
          !(chunk.status && isCompletedStatus(chunk.status)) &&
          chunk.scheduledStart
      )
      .sort(sortTaskChunksByScheduledEnd)

    if (!incompleteChunks.length) {
      return null
    }
    return incompleteChunks[incompleteChunks.length - 1].scheduledEnd || null
  }
  return task.scheduledEnd || DateTime.now().toISO()
}

export const getLastScheduledEndFromTaskV2 = (
  task: Pick<TaskSchema, 'scheduledEnd'>,
  chunks: Pick<
    TaskSchema,
    'completedTime' | 'scheduledStart' | 'scheduledEnd'
  >[]
): string | null => {
  if (chunks.length > 0) {
    const incompleteChunks = chunks
      .filter((chunk) => chunk.completedTime == null && chunk.scheduledStart)
      .sort(sortTaskChunksByScheduledEnd)
    if (!incompleteChunks.length) {
      return null
    }
    return incompleteChunks[incompleteChunks.length - 1].scheduledEnd || null
  }

  return task.scheduledEnd || DateTime.now().toISO()
}

/**
 * @deprecated to be replaced with getTaskScheduledDateString on switching to past_due v2 endpoint
 */
export const legacyGetTaskScheduledDateString = (task: PMTaskType): string => {
  if (task.isUnfit) {
    return 'Could not fit task'
  } else if (isTaskScheduled(task)) {
    const lastScheduled = getLastScheduledEndFromPmTask(task)
    if (lastScheduled == null) return ''

    return DateTime.fromISO(lastScheduled).toFormat("ccc LLL d 'at' h:mma")
  }
  return 'Pending'
}

export const getTaskScheduledDateString = (task: TaskSchema): string => {
  if ('scheduledStatus' in task && task.scheduledStatus === 'UNFIT_PAST_DUE') {
    return 'Could not fit task'
  }

  return 'estimatedCompletionTime' in task && task.estimatedCompletionTime
    ? DateTime.fromISO(task.estimatedCompletionTime).toFormat(
        "ccc LLL d 'at' h:mma"
      )
    : 'Pending'
}

export const isTaskScheduled = (task?: PMTaskType) => {
  return Boolean(task != null && (task.scheduledStart || task.chunks?.length))
}

type IsTaskPastDueFields =
  | 'priorityLevel'
  | 'needsReschedule'
  | 'scheduledStatus'

type NullableUndefinedFields<T> = {
  [K in keyof T]: undefined extends T[K] ? T[K] | null : T[K]
}
type TaskWithPastDue = NullableUndefinedFields<
  Pick<PMTaskType, IsTaskPastDueFields>
>

type NormalTaskSchemaWithPastDue = NullableUndefinedFields<
  Pick<NormalTaskSchema, IsTaskPastDueFields>
>
type RecurringTaskSchemaWithPastDue = NullableUndefinedFields<
  Pick<RecurringInstanceSchema, IsTaskPastDueFields>
>

type TaskV2WithPastDue =
  | NormalTaskSchemaWithPastDue
  | RecurringTaskSchemaWithPastDue

export function isTaskPastDue(task: TaskWithPastDue | TaskV2WithPastDue) {
  if (task.priorityLevel === 'ASAP' || task.needsReschedule) {
    return false
  }

  return (
    task.scheduledStatus === 'PAST_DUE' ||
    task.scheduledStatus === 'UNFIT_PAST_DUE'
  )
}

function isDeadlineInFuture(task: Pick<PMTaskType, 'dueDate'>) {
  const { dueDate: dueDateString } = task
  if (!dueDateString) return true

  const now = DateTime.now()
  const dueDate = parseDate(dueDateString)

  return dueDate > now
}

export function shouldAllowPromoteToHardDeadline(
  task: Pick<PMTaskType, 'dueDate' | 'deadlineType' | 'type'>
) {
  return (
    isDeadlineInFuture(task) &&
    task.deadlineType !== 'HARD' &&
    task.type !== 'RECURRING_INSTANCE'
  )
}

export function shouldAllowASAP(task: Pick<PMTaskType, 'type'> | TaskSchema) {
  return task.type !== 'RECURRING_INSTANCE'
}

export type RecurrenceInfo = {
  frequency: RecurringTask['frequency']
  days: RecurringTask['days']
}

export function canTaskBeExtended(
  task?: PMTaskType,
  recurringInfo?: RecurrenceInfo | null
) {
  if (task == null) return false
  if (recurringInfo?.frequency === 'weekly') return false
  if (task.endDate == null || task.dueDate == null) return true

  return parseDate(task.dueDate) < parseDate(task.endDate)
}

export function getRecurrenceInfo(
  task?: Pick<NormalOrRecurringTask, 'frequency' | 'days'>
): RecurrenceInfo | null {
  if (task == null || task.frequency == null) return null

  return { frequency: task.frequency, days: task.days ?? [] }
}

export function getStartDateOrToday(
  startDate: PMTaskType['startDate']
): DateTime {
  return startDate
    ? parseDate(startDate).startOf('day')
    : DateTime.now().startOf('day')
}
