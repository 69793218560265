import { classed } from '@motion/theme'
import { Toggle } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { generateBillingFormula } from '@motion/ui-logic/billing'

import { twMerge } from 'tailwind-merge'

import { BucketSeatsDropdown } from '../bucket-seats-dropdown'

type SharedBillingPlanProps = {
  text: string
  subText?: string
  price: number
  trialLength?: number
  isAnnual: boolean
  isTeam: boolean
}

type IndividualBillingPlanProps = SharedBillingPlanProps & {
  isTeam: false
  setBucket: undefined
  bucket: undefined
}

type TeamBillingPlanProps = SharedBillingPlanProps & {
  isTeam: true
  setBucket: (bucket: number) => void
  bucket: number
}

export type BillingPlanProps = IndividualBillingPlanProps | TeamBillingPlanProps

const BillingPlan = ({
  text,
  subText,
  price,
  trialLength,
  isAnnual,
  setBucket,
  isTeam,
  bucket,
}: BillingPlanProps) => {
  return (
    <div
      className={twMerge(
        'bg-semantic-neutral-bg-default rounded-lg flex flex-col lg:flex-row justify-between items-center py-3 px-4 text-semantic-neutral-text-default}',
        isTeam && 'mb-3'
      )}
    >
      <div className='flex w-full justify-between flex-row lg:flex-col lg:items-start lg:gap-1'>
        <div className='flex flex-row'>
          <span className='font-semibold text-base text-semantic-neutral-text-default'>
            {text}
            {!isTeam && ` ($${price}/mo)`}
          </span>
        </div>
        {isTeam && bucket && setBucket ? (
          <div className='flex flex-row gap-1.5 items-center'>
            <BucketSeatsDropdown selectedBucket={bucket} onChange={setBucket} />
            <span className='text-xs text-semantic-neutral-text-subtle'>
              ${price}/mo per seat
            </span>
          </div>
        ) : (
          subText && <BillingSubtext>{subText}</BillingSubtext>
        )}
      </div>
      <div className='flex flex-col w-full mt-[18px] gap-2 lg:items-end lg:mt-0 lg:gap-1'>
        {trialLength ? (
          <>
            <div className='text-semantic-primary-text-default'>
              <span className='text-lg leading-5 mr-1 font-semibold'>${0}</span>
              <span className='font-normal'>
                for {trialLength} day free trial
              </span>
            </div>
            <BillingSubtext>
              {templateStr('then {{formula}}', {
                formula: generateBillingFormula({
                  isTeam: !!isTeam,
                  isAnnual: !!isAnnual,
                  seats: isTeam ? bucket : undefined,
                  monthlyPrice: price,
                }),
              })}
            </BillingSubtext>
          </>
        ) : (
          <>
            <div className='flex flex-col gap-0.5 items-end'>
              <div>
                <span className='text-lg font-semibold text-semantic-neutral-text-subtle'>
                  ${price * (bucket ?? 1) * (isAnnual ? 12 : 1)}
                </span>
                <span className='text-xs font-semibold text-semantic-neutral-text-subtle'>
                  /{isAnnual ? 'year' : 'month'}
                </span>
              </div>
              <p className='text-xs text-semantic-neutral-text-subtle'>
                billed {isAnnual ? 'annually' : 'monthly'}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const BillingSubtext = classed('span', {
  base: 'text-semantic-neutral-text-subtle text-xs font-normal',
})

type BillingPlanSelectorProps = {
  isAnnual: boolean
  setIsAnnual: (isAnnual: boolean) => void
  savingsPercent: number
  title?: string
}

export const BillingPlanSelector = ({
  isAnnual,
  setIsAnnual,
  savingsPercent,
  title = 'Your plan',
  ...billingPlanProps
}: BillingPlanSelectorProps & BillingPlanProps) => {
  return (
    <div>
      <div className='flex flex-row justify-between items-center mb-3'>
        <p className='text-semantic-neutral-text-default text-xl font-semibold'>
          {title}
        </p>
        <div className='flex flex-row gap-2'>
          <Toggle
            checked={isAnnual}
            onChange={(e) => setIsAnnual(e.currentTarget.checked)}
          >
            <span>Billed yearly (Save {savingsPercent}%)</span>
          </Toggle>
        </div>
      </div>
      <div className='border border-semantic-neutral-border-subtle rounded bg-semantic-neutral-bg-default'>
        <BillingPlan {...billingPlanProps} isAnnual={isAnnual} />
      </div>
    </div>
  )
}
