import { merge as toolkitMerge } from 'es-toolkit'

import { cloneDeep } from './clone-deep'

// TODO: Use mergeWith to reduce the number of clones

export function merge<T1, T2>(obj: T1, source: T2): T1 & T2
export function merge<T1, T2, T3>(
  obj: T1,
  source1: T2,
  source2: T3
): T1 & T2 & T3
export function merge<T1, T2, T3, T4>(
  obj: T1,
  source1: T2,
  source2: T3,
  source3: T4
): T1 & T2 & T3 & T4
export function merge<T1, T2, T3, T4, T5>(
  obj: T1,
  source1: T2,
  source2: T3,
  source3: T4,
  source4: T5
): T1 & T2 & T3 & T4 & T5
export function merge(target: any, ...objects: any[]): any {
  if (objects.length === 0) return target

  for (const obj of objects) {
    mergeCore(target, obj)
  }

  return target
}

function mergeCore(target: any, source: any) {
  if (source == null) return target
  return toolkitMerge(target, cloneDeep(source))
}
