import * as ai from './ai'
import * as apiKeys from './api-keys'
import * as blockingTimeslot from './blocking-timeslot'
import * as calendarEvents from './calendar-events'
import * as calendars from './calendars'
import * as commentsV2 from './comments-v2'
import * as customFields from './custom-fields'
import * as emailAccounts from './email-accounts'
import * as feedV2 from './feed-v2'
import * as privacy from './privacy'
import * as projectsDefinitions from './project-definitions'
import * as projects from './projects'
import * as projectsV2 from './projects-v2'
import * as recentlyOpened from './recently-opened'
import * as recurringTasks from './recurring-tasks'
import * as referrals from './referrals'
import * as scheduledEntities from './scheduled-entities'
import * as search from './search'
import * as stripe from './stripe'
import * as subscriptions from './subscriptions'
import * as tasksV2 from './tasks-v2'
import * as teamSchedule from './team-schedule'
import * as teamTasks from './team-tasks'
import * as teams from './teams'
import * as templates from './templates'
import * as tutorials from './tutorials'
import * as userSettings from './user-settings'
import * as users from './users'
import * as usersV2 from './users-v2'
import * as views from './views-v2'
import * as workspaces from './workspaces'
import * as workspacesV2 from './workspaces-v2'

export const API = {
  ai,
  apiKeys,
  blockingTimeslot,
  calendarEvents,
  calendars,
  commentsV2,
  customFields,
  emailAccounts,
  feedV2,
  privacy,
  projects,
  projectsDefinitions,
  projectsV2,
  recentlyOpened,
  recurringTasks,
  referrals,
  scheduledEntities,
  search,
  stripe,
  subscriptions,
  tasksV2,
  teams,
  teamSchedule,
  teamTasks,
  templates,
  tutorials,
  users,
  userSettings,
  usersV2,
  views,
  workspaces,
  workspacesV2,
}
