import { type EventConferenceType } from '@motion/shared/common'

export type CalendarEvent = {
  id: string
  providerId: string
  title: string
  description: string | null
  type: string
  iCalUid: string
  start: string
  end: string
  isAllDay: boolean
  status: CalendarEventStatus
  location?: string
  isCancelled: boolean
  attendees?: EventAttendee[]
  conferenceLink: string | null
  teamTaskId: string | null
  visibility: string
  recurrence: string | null
  recurringEventId: string
  areAttendeesHidden: boolean | null
  canAttendeesInvite: boolean | null
  canAttendeesModify: boolean | null
  url: string
  colorId: string | null
  bookingLinkId: string | null
  bookingPriority: string | null
  isDeleted: boolean
  sourceEventId: string | null
  sourceCalendarId: string | null
  travelTimeId: string | null
  travelTimeBefore: string | null
  travelTimeAfter: string | null
  calendarId: string
  email: string
  organizer?: {
    displayName?: string
    email?: string
  }
  recurringParentId: string | null
}

export type EventAttendeeStatus =
  | 'accepted'
  | 'declined'
  | 'needsAction'
  | 'tentative'

export type EventAttendee = {
  displayName?: string
  email: string
  isOptional: boolean
  isOrganizer: boolean
  status: EventAttendeeStatus
}

export type DeprecatedCalendar = {
  id: string
  email: string
  accessRole?: string
  active?: boolean
  mine?: boolean
  primary?: boolean
  owner?: string
  title?: string
  provider?: 'google' | 'microsoft'
  modified?: boolean
  frequent?: boolean
  colorClass?: string
  editable?: boolean
  visible?: boolean
}

export type CalendarList = Record<string, DeprecatedCalendar[]>

export const CalendarEventStatuses = ['FREE', 'BUSY'] as const
export type CalendarEventStatus = (typeof CalendarEventStatuses)[number]

/**
 * @deprecated Use the larger EventConferenceTypes from shared/common if possible
 */
export const LimitedEventConferenceTypes = [
  'none',
  'meet',
  'zoom',
  'hangoutsMeet',
  'teamsForBusiness',
  'phone',
  'customLocation',
] as const satisfies EventConferenceType[]

/**
 * @deprecated Use the larger EventConferenceType from shared/common if possible
 */
export type LimitedEventConferenceType =
  (typeof LimitedEventConferenceTypes)[number]

export const MobileEventConferenceTypes = [
  'zoom',
  'hangoutsMeet',
  'teamsForBusiness',
] as const satisfies EventConferenceType[]

export type MobileEventConferenceType =
  (typeof MobileEventConferenceTypes)[number]

export const EventConferenceTypeHumanReadable = {
  none: 'None',
  zoom: 'Zoom',
  hangoutsMeet: 'Google Meet',
  meet: 'Meet',
  teamsForBusiness: 'Teams Meeting',
  phone: 'Phone Call',
  customLocation: 'Custom Location',
  eventHangout: 'Event Hangout',
  // eventNamedHangout: 'eventNamedHangout', ??
  unknown: 'Unknown',
  skypeForBusiness: 'Skype',
  skypeForConsumer: 'Skype',
} as const

export type EventConferenceTypeHumanReadable =
  (typeof EventConferenceTypeHumanReadable)[keyof typeof EventConferenceTypeHumanReadable]
